import { useEffect, useState } from 'react';
import { NavDropdown } from 'react-bootstrap';
import SimpleBar from 'simplebar-react';

import { Spinner } from '@/components/Elements/Spinner';
import { Notification } from '@/components/Notifications/Notification';
import { useGetNotificationQuery } from '@/generated/graphql';
import { useAuth } from '@/lib/auth';

export const Notifications = () => {
  const { user } = useAuth();
  const { data, refetch, isLoading } = useGetNotificationQuery({
    userCode: user?.userCode ?? '',
  });

  const [indicator, setIndicator] = useState(<></>);

  useEffect(() => {
    const num = data?.getNotification?.edges.length;
    if (num) {
      setIndicator(<span className="indicator">{num}</span>);
    } else {
      setIndicator(<></>);
    }
  }, [data]);

  return (
    <NavDropdown
      id="messagesDropdown"
      title={
        <div className="nav-icon">
          <div className="position-relative">
            <i className="bi bi-chat-left-text" />
            {indicator}
          </div>
        </div>
      }
    >
      <div className="list-group">
        <NavDropdown.Item
          key={0}
          eventKey={`0`}
          className="list-group-item"
        >{`${data?.getNotification?.edges.length}件の新しいメッセージがあります`}</NavDropdown.Item>
        <SimpleBar style={{ maxHeight: '350px', width: '500px' }} className="sidebar-simplebar">
          {data?.getNotification?.edges.map((data) => {
            return (
              <Notification
                key={`${data?.node?.no}`}
                date={data?.node?.updateDate}
                title={data?.node?.subject}
                message={data?.node?.message}
              />
            );
          })}
        </SimpleBar>
      </div>
      <div className="dropdown-menu-footer">
        <a
          role="button"
          tabIndex={-1}
          onClick={() => refetch()}
          aria-hidden="true"
          className="text-muted"
        >
          {isLoading && <Spinner size="sm" className="text-current fixed mt-1" />}
          最新の情報に更新する
        </a>
      </div>
    </NavDropdown>
  );
};

export enum WithdrawalStateEnum {
  // 出金状態
  NOT_CONFIRM = '0',
  CONFIRM = '1',
}

export const WithdrawalState = [
  { label: '未確定', value: WithdrawalStateEnum.NOT_CONFIRM },
  { label: '確定', value: WithdrawalStateEnum.CONFIRM },
];

import { createContext, useContext, useEffect, useState } from 'react';

import { Sidemenu } from '../Elements/Sidemenu';
import { Head } from '../Head';

import { Header } from './Header';

export const TitleContext = createContext<[string, (title: string) => void]>(['', () => {}]);

export const CollapseContext = createContext<boolean>(true);

type TitleProps = {
  children: string;
};

export const Title = ({ children }: TitleProps) => {
  const [, setTitle] = useContext(TitleContext);
  useEffect(() => {
    setTitle(children);
  }, [children, setTitle]);
  return <></>;
};

type LayoutProps = {
  children: React.ReactNode;
};

export const Layout = ({ children }: LayoutProps) => {
  const [isCollapse, toggleCollapse] = useState(true);
  const [title, setTitle] = useState('');

  return (
    <TitleContext.Provider value={[title, setTitle]}>
      <CollapseContext.Provider value={isCollapse}>
        <div className="wrapper">
          <Sidemenu isCollapse={isCollapse}>test</Sidemenu>
          <div className="main">
            <Head title={`${title}`} />
            <Header title={title} isCollapse={isCollapse} toggleCollapse={toggleCollapse} />
            <main className="content">{children}</main>
          </div>
        </div>
      </CollapseContext.Provider>
    </TitleContext.Provider>
  );
};

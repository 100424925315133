import clsx from 'clsx';
import { UseFormRegisterReturn } from 'react-hook-form';

import { FieldWrapper, FieldWrapperPassThroughProps } from './FieldWrapper';

type NumberFieldProps = FieldWrapperPassThroughProps & {
  step?: number;
  className?: string;
  registration: Partial<UseFormRegisterReturn>;
  defaultValue?: number;
  placeholder?: string;
  minValue?: string;
  maxValue?: string;
};

export const NumberField = (props: NumberFieldProps) => {
  const {
    step = 0,
    label,
    className,
    registration,
    error,
    defaultValue,
    placeholder,
    minValue,
    maxValue,
  } = props;
  return (
    <FieldWrapper label={label} error={error}>
      <input
        type="number"
        step={step}
        className={clsx('form-control', className)}
        placeholder={placeholder}
        defaultValue={defaultValue}
        min={minValue}
        max={maxValue}
        {...registration}
      />
    </FieldWrapper>
  );
};

type EnvMessageProps = {
  envMessage: string;
  color: string;
};

export const EnvMessage = ({ envMessage, color }: EnvMessageProps) => {
  return (
    <>
      <div
        style={{
          left: '50%',
          transform: 'translateX(-50%)',
          WebkitTransform: 'translateX(-50%)',
          position: 'absolute',
          backgroundColor: color,
          borderStyle: 'solid',
          fontSize: '3rem',
          padding: '0.5rem 3rem',
          fontWeight: 'bold',
          zIndex: 100000,
        }}
      >
        {envMessage}
      </div>
    </>
  );
};

import * as grid from '@grapecity/wijmo.grid';
import { FlexGrid } from '@grapecity/wijmo.react.grid';
import React, { createRef, RefObject } from 'react';

import { BaseFlexGridProps, SizeProps } from './BaseFlexGrid';

type BaseFlexGridDialogProps = BaseFlexGridProps & {
  targetViewRef: RefObject<HTMLElement>;
};

export class BaseFlexGridDialog extends React.Component<BaseFlexGridDialogProps> {
  sizeProps: SizeProps;
  virtualizedWindow: React.RefObject<HTMLDivElement>;
  virtualizedContainer: React.RefObject<HTMLDivElement>;
  virtualizedStart: React.RefObject<HTMLDivElement>;
  virtualizedEnd: React.RefObject<HTMLDivElement>;
  navbarElm: HTMLDivElement | undefined;
  controlAreaElm: HTMLDivElement | undefined;
  flexgrid: grid.FlexGrid | undefined;
  refreshFlg: boolean;
  objectRef: React.RefObject<HTMLObjectElement>;
  raf: number | undefined;
  scrollDelta: number;

  tmpTargetViewRef: HTMLElement | undefined | null;
  dialogFooter: HTMLDivElement | undefined;

  constructor(props: BaseFlexGridDialogProps) {
    super(props);
    this.virtualizedWindow = createRef<HTMLDivElement>();
    this.virtualizedContainer = createRef<HTMLDivElement>();
    this.virtualizedStart = createRef<HTMLDivElement>();
    this.virtualizedEnd = createRef<HTMLDivElement>();
    this.navbarElm = undefined;
    this.controlAreaElm = undefined;
    this.objectRef = createRef<HTMLObjectElement>();
    this.flexgrid = undefined;
    this.sizeProps = {
      headerHeight: 0,
      vWinHeight: 0,
      vWinStartPos: 0,
      vWinEndPos: 0,
      fgHeight: 0,
      stickyScrollPos: 0,
    };

    this.refreshFlg = false;
    this.raf = undefined;
    this.scrollDelta = 0;

    this.tmpTargetViewRef = undefined;
    this.dialogFooter = undefined;
  }

  // 各種サイズの取得
  updateHeaderSize() {
    this.sizeProps.headerHeight = 51;
    if (this.virtualizedStart?.current)
      this.sizeProps.vWinStartPos = this.virtualizedStart.current.getBoundingClientRect().top;
    this.sizeProps.stickyScrollPos = this.sizeProps.vWinStartPos - this.sizeProps.headerHeight;
  }

  // VirtualizedWindowのボックスサイズ更新
  virtualizedWindowUpdate() {
    const vwElem = this.virtualizedWindow?.current;
    const s = this.flexgrid;
    if (s && vwElem) {
      const eCHdr = s._eCHdr;
      const cells = s.cells.hostElement;
      const vwH = eCHdr.scrollHeight + cells.scrollHeight;
      // スクロールバーのサイズを取得
      const scrollbarHeight = s._root.offsetHeight - s._root.clientHeight;
      this.sizeProps.fgHeight = vwH + scrollbarHeight;
      // 高さを更新
      vwElem.style.height = `${this.sizeProps.fgHeight}px`;
      if (this.virtualizedContainer?.current && this.virtualizedStart?.current)
        this.virtualizedContainer.current.style.width = `${this.virtualizedStart.current.clientWidth}px`;
      this.updateHeaderSize();
    }
  }

  updateFlexBoxHeight(height: number) {
    const s = this.flexgrid;
    if (s) {
      if (this.dialogFooter) {
        height = height - this.dialogFooter.offsetHeight;
      }
      s.hostElement.style.height = `${height - 60}px`;
    }
  }

  // 固定化切り替え
  deciedFixedFlexGrid() {
    const s = this.flexgrid;
    const container = this.virtualizedContainer;
    const vStart = this.virtualizedStart;
    const vEnd = this.virtualizedEnd;
    if (s && vStart?.current && vEnd?.current && container?.current) {
      if (this.sizeProps.stickyScrollPos >= 0) {
        // 固定化解除状態
        container.current.style.position = 'relative';
        container.current.style.top = '';
        // スクロール
        s._root.scrollTop = 0;
        // ViewWindowの調整
        this.updateFlexBoxHeight(window.innerHeight - this.sizeProps.vWinStartPos);
      } else {
        // ヘッダー固定化
        container.current.style.position = 'fixed';
        container.current.style.top = `${this.sizeProps.headerHeight}px`;
        // スクロール
        s._root.scrollTop = Math.abs(this.sizeProps.stickyScrollPos);
        // ViewWindowの調整
        this.updateFlexBoxHeight(window.innerHeight - this.sizeProps.headerHeight);
      }
      // グリッドの高さが小さい場合、高さがフィットするように調整
      // ダイアログはいらないかも
      // const vEndTop = vEnd.current.getBoundingClientRect().top;
      // const containerTop = container.current.getBoundingClientRect().top;
      // if (vEndTop < window.innerHeight) {
      //   // FlexGridのViewWindowの調整
      //   const height = vEndTop - containerTop;
      //   this.updateFlexBoxHeight(height);
      // }
    }
  }

  // スクロール位置の更新
  scrollPosUpdate() {
    const s = this.flexgrid;
    if (s) {
      if (this.sizeProps.stickyScrollPos >= 0) {
        // スクロール
        s._root.scrollTop = 0;
      } else {
        // スクロール
        s._root.scrollTop = Math.abs(this.sizeProps.stickyScrollPos);
      }
    }
  }

  // documentスクロール時の処理
  scrollHandle() {
    if (this.raf) {
      cancelAnimationFrame(this.raf);
    }
    this.raf = requestAnimationFrame(() => {
      this.virtualizedWindowUpdate();
      this.deciedFixedFlexGrid();
      this.scrollPosUpdate();
    });
  }

  scrollGridEvt(e: any) {
    if (e.deltaX === 0 && e.shiftKey === false) {
      e.preventDefault();
      const delta = Math.sign(e.deltaY) * 120;
      if (this.raf) {
        this.scrollDelta += delta;
        cancelAnimationFrame(this.raf);
      }
      this.raf = requestAnimationFrame(() => {
        this.scrollDelta += delta;
        this.props.targetViewRef.current?.scrollBy({
          left: 0,
          top: this.scrollDelta,
          behavior: 'smooth',
        });
        this.scrollDelta = 0;
      });
    }
  }

  initialized(s: grid.FlexGrid): void {
    this.flexgrid = s;
    s._root.style.overflowY = 'hidden';
    s._root.style.overflowX = 'scroll';
    s.hostElement.addEventListener('wheel', this.scrollGridEvt.bind(this), true);
    this.props.initialized?.call(null, s);
  }
  itemsSourceChanged(s: grid.FlexGrid) {
    this.refreshFlg = true;
    this.props.itemsSourceChanged?.call(null, s);
  }
  loadedRows(s: grid.FlexGrid): void {
    this.props.loadedRows?.call(null, s);
  }
  updatingLayout(s: grid.FlexGrid): void {
    this.props.updatingLayout?.call(null, s);
  }
  updatedLayout(s: grid.FlexGrid): void {
    if (this.refreshFlg) {
      this.virtualizedWindowUpdate();
      this.deciedFixedFlexGrid();
      this.refreshFlg = false;
      s.refresh();
    }
    this.props.updatedLayout?.call(null, s);
  }
  updatedView(s: grid.FlexGrid): void {
    this.props.updatedView?.call(null, s);
  }
  scrollPositionChanged(s: grid.FlexGrid): void {
    this.props.scrollPositionChanged?.call(null, s);
  }
  rowAdded(s: grid.FlexGrid): void {
    // this.virtualizedWindowUpdate();
    // this.deciedFixedFlexGrid();
    this.refreshFlg = true;
    this.props.rowAdded?.call(null, s);
  }
  deletedRow(s: grid.FlexGrid): void {
    // this.virtualizedWindowUpdate();
    // this.deciedFixedFlexGrid();
    this.refreshFlg = true;
    this.props.deletedRow?.call(null, s);
  }

  componentDidMount() {
    this.navbarElm = document.querySelector<HTMLDivElement>('.navbar') as HTMLDivElement;
    this.controlAreaElm = document.querySelector<HTMLDivElement>('.control-area') as HTMLDivElement;
    this.updateHeaderSize();
    const obj = this.objectRef.current;
    if (obj && obj.contentDocument && obj.contentDocument.defaultView) {
      obj.contentDocument.defaultView.addEventListener('resize', this.scrollHandle.bind(this));
    }
  }
  componentDidUpdate() {
    if (this.tmpTargetViewRef !== this.props.targetViewRef?.current) {
      this.dialogFooter = this.props.targetViewRef.current?.querySelector<HTMLDivElement>(
        '.fk__offcanvas-set-btn'
      ) as HTMLDivElement;
      if (this.props.targetViewRef.current) {
        this.props.targetViewRef.current.addEventListener('scroll', this.scrollHandle.bind(this));
      }
      this.tmpTargetViewRef = this.props.targetViewRef?.current;
    }
  }
  componentWillUnmount() {
    const obj = this.objectRef.current;
    if (obj && obj.contentDocument && obj.contentDocument.defaultView) {
      obj.contentDocument.defaultView.removeEventListener('resize', this.scrollHandle.bind(this));
    }
    if (this.props.targetViewRef.current) {
      this.props.targetViewRef.current.removeEventListener('scroll', this.scrollHandle.bind(this));
    }
  }

  render() {
    return (
      <>
        <object
          ref={this.objectRef}
          tabIndex={-1}
          aria-label="object"
          type={'text/html'}
          data={'about:blank'}
          title={''}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            pointerEvents: 'none',
            zIndex: -1,
            opacity: 0,
          }}
        />
        <div className="virtualizedWindow" ref={this.virtualizedWindow}>
          <div className="virtualizedStart" ref={this.virtualizedStart} style={{ width: '100%' }} />
          <div className="virtualizedContainer" ref={this.virtualizedContainer}>
            <FlexGrid
              {...this.props}
              initialized={this.initialized.bind(this)}
              itemsSourceChanged={this.itemsSourceChanged.bind(this)}
              loadedRows={this.loadedRows.bind(this)}
              updatingLayout={this.updatingLayout.bind(this)}
              updatedLayout={this.updatedLayout.bind(this)}
              updatedView={this.updatedView.bind(this)}
              scrollPositionChanged={this.scrollPositionChanged.bind(this)}
              rowAdded={this.rowAdded.bind(this)}
              deletedRow={this.deletedRow.bind(this)}
            >
              {this.props.children}
            </FlexGrid>
          </div>
        </div>
        <div className="virtualizedEnd" ref={this.virtualizedEnd} />
      </>
    );
  }
}

/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import clsx from 'clsx';
import { useState } from 'react';
import { Collapse } from 'react-bootstrap';

type MenuGroupProps = {
  children: React.ReactNode;
  menuTitle: string;
};

export const MenuGroup = ({ menuTitle, children }: MenuGroupProps) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <li className="sidebar-item">
        <a
          role="button"
          onClick={() => setOpen(!open)}
          className={clsx('sidebar-link', open ? '' : 'collapsed')}
          data-bs-toggle="collapse"
        >
          <i className="fas fa-book align-middle" />
          <span className="align-middle">{menuTitle}</span>
        </a>
        <Collapse in={open}>
          <ul className="sidebar-dropdown list-unstyled">{children}</ul>
        </Collapse>
      </li>
    </>
  );
};

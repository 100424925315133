import 'simplebar/dist/simplebar.min.css';

import SimpleBar from 'simplebar-react';

import { STAGE } from '@/config';

import { BookmarkList } from './BookmarkList';
import { useBookmark } from './hooks/useBookmark';
import { useMenuData } from './hooks/useMenuData';
import { MenuGroup } from './MenuGroup';
import { MenuLink } from './MenuLink';
import { MenuSeparator } from './MenuSeparator';

type SidemenuProps = {
  children: React.ReactNode;
  isCollapse: boolean;
};

export const Sidemenu = (props: SidemenuProps) => {
  const { bookmark, addBookmark, removeBookmark } = useBookmark();

  const menus = useMenuData();

  return (
    <nav id="sidebar" className={props.isCollapse ? 'sidebar collapsed' : 'sidebar'}>
      <SimpleBar style={{ maxHeight: '100vh' }} className="sidebar-simplebar" tabIndex={-1}>
        <div className="sidebar-content js-simplebar" data-simplebar="init">
          <p className="sidebar-heading py-4 px-4 align-middle">Menu</p>
          <ul className="sidebar-nav">
            <li className="sidebar-item">
              <a className="sidebar-link" href="/app" tabIndex={-1}>
                <i className="fas fa-home" />
                <span className="align-middle">ホーム</span>
              </a>
            </li>
            <li className="sidebar-separator" />
            <li className="sidebar-item">
              <a data-bs-target="#sidemenu01" data-bs-toggle="collapse" className="sidebar-link">
                <i className="fas fa-star" />
                <span className="align-middle">よく使う機能</span>
              </a>
              <BookmarkList removeBookmark={removeBookmark} bookmark={bookmark} />
            </li>
            <MenuSeparator />

            {menus &&
              menus.map((group) => {
                if (!(STAGE === 'DEMO' && group.disabled)) {
                  return (
                    <MenuGroup menuTitle={group.groupName} key={group.groupName}>
                      {group.menus.map((link) => {
                        if (!(STAGE === 'DEMO' && link.disabled)) {
                          return (
                            <MenuLink
                              to={link.to}
                              end={link.end}
                              key={link.title}
                              onBookmark={(to, actived) => {
                                actived ? removeBookmark(to) : addBookmark(to);
                              }}
                              active={bookmark.includes(link.to.toString())}
                            >
                              {link.title}
                            </MenuLink>
                          );
                        }
                      })}
                    </MenuGroup>
                  );
                }
              })}
          </ul>
        </div>
      </SimpleBar>
    </nav>
  );
};

import ExcelJS from 'exceljs';
import { useCallback } from 'react';

import { isInvalidDate, toStringYyyyMmDdSlash } from '@/utils/dateUtils';

import { ExportData, ExportDataProps } from './ExportData';

type ExportReportProps = ExportDataProps &
  (
    | { fileName: string; dateFrom: string; dateTo: string; customDate?: string }
    | { fileName: string; dateFrom?: string; dateTo?: string; customDate: string }
  );

const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  if (isInvalidDate(date)) {
    return '';
  } else {
    return toStringYyyyMmDdSlash(date);
  }
};

export const ExportReport = (props: ExportReportProps) => {
  const preDownloadHandler = useCallback(
    (workbook: ExcelJS.Workbook) => {
      if (props.options?.preDownloadHandler) props.options?.preDownloadHandler(workbook);
      const worksheet = workbook.getWorksheet('sheet');

      worksheet.getRow(1).eachCell((cell) => {
        cell.border = {
          bottom: { style: 'medium' },
        };
      });

      worksheet.insertRow(1, []);
      if (props.dateFrom && props.dateTo) {
        worksheet.insertRow(1, [
          `集計期間：${formatDate(props.dateFrom)}～${formatDate(props.dateTo)}`,
        ]);
      } else if (props.customDate) {
        worksheet.insertRow(1, [`集計期間：${props.customDate}`]);
      }

      worksheet.insertRow(1, [`レポート名：${props.fileName}`]);
    },
    [props.dateFrom, props.dateTo, props.customDate, props.fileName, props.options]
  );
  return <ExportData {...props} options={{ preDownloadHandler: preDownloadHandler }} />;
};

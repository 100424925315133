import ExcelJS from 'exceljs';
import PromiseQueue from 'promise-queue';
import { useState, useCallback } from 'react';
import { Modal } from 'react-bootstrap';
import { z, ZodError, ZodType, ZodTypeDef } from 'zod';

import { ImportTypeEnum, _IMPORT_TYPE_KEY } from '@/business_code';
import { Button } from '@/components/Elements/Button';
import { Dropzone } from '@/components/Elements/Dropzone';
import { Spinner } from '@/components/Elements/Spinner';

import { buildData, ImportErrors, ImportMapping } from '../lib/common';
import { useImportStatus } from '../lib/useImportStatus';

import { HeaderColumn, ImportGrid } from './ImportGrid';

type ImportDialogProps<TFormValues, Schema> = {
  mapping: ImportMapping<TFormValues>[];
  createSchema: Schema;
  updateSchema: Schema;
  deleteSchema: Schema;
  createHandler: (data: TFormValues) => Promise<any>;
  updateHandler: (data: TFormValues) => Promise<any>;
  deleteHandler: (data: TFormValues) => Promise<any>;
  onClosed?: () => void;
  onCompleted?: () => void;
  maxConcurrent: number;
  title?: string;
};

const importTypeSchema = z.object({
  importType: z.nativeEnum(ImportTypeEnum),
});

export enum ProcessStatus {
  NONE,
  UPLOADING,
  IMPORTING,
  IMPORTED,
  IMPORTERROR,
  VALIDATIONERROR,
}

export const ImportDialog = <
  TFormValues extends Record<string, unknown> = Record<string, unknown>,
  Schema extends ZodType<unknown, ZodTypeDef, unknown> = ZodType<unknown, ZodTypeDef, unknown>
>({
  mapping,
  createSchema,
  updateSchema,
  deleteSchema,
  createHandler,
  updateHandler,
  deleteHandler,
  onClosed,
  onCompleted,
  maxConcurrent,
  title,
}: ImportDialogProps<TFormValues, Schema>) => {
  const [show, setShow] = useState(false);
  const [processStatus, setProcessStatus] = useState<ProcessStatus>(ProcessStatus.NONE);

  const [importData, setImportData] = useState<any[]>([]);

  const [importStatus, importStatusDispatch] = useImportStatus();
  const [errors, setErrors] = useState<any[]>([]);
  const [columns, setColumns] = useState<HeaderColumn[]>([]);

  /**
   * ステータスリセット処理
   */
  const reset = useCallback(() => {
    setProcessStatus(ProcessStatus.NONE);
    setImportData([]);
    setErrors([]);
    importStatusDispatch({ type: 'RESET', value: 0 });
  }, [importStatusDispatch]);

  /**
   * バリデーション
   * @param importData 構造化データ
   * @returns バリデーションメッセージ
   */
  const validation = useCallback(
    (importData: TFormValues[]): ImportErrors[] => {
      const errors: ImportErrors[] = [];
      importData.forEach((data, idx) => {
        try {
          importTypeSchema.parse(data);
          if (data[_IMPORT_TYPE_KEY] === ImportTypeEnum.CREATE) {
            createSchema.parse(data);
          } else if (data[_IMPORT_TYPE_KEY] === ImportTypeEnum.UPDATE) {
            updateSchema.parse(data);
          } else if (data[_IMPORT_TYPE_KEY] === ImportTypeEnum.DELETE) {
            deleteSchema.parse(data);
          }
        } catch (err) {
          if (err instanceof ZodError) {
            errors[idx] = err.flatten().fieldErrors as ImportErrors;
          }
        }
      });
      return errors;
    },
    [createSchema, updateSchema, deleteSchema]
  );

  /**
   * 個別のインポート処理
   * @param importData 構造化データ
   * @param idx 処理番号
   * @returns Promise
   */
  const _execute = useCallback(
    (data: TFormValues, idx: number): Promise<TFormValues> => {
      importStatusDispatch({ type: 'PROCESSING', value: idx });
      if (data[_IMPORT_TYPE_KEY] === ImportTypeEnum.CREATE) {
        return new Promise((resolve, reject) => {
          createHandler(data)
            .then((r) => {
              importStatusDispatch({ type: 'COMPLETE', value: idx });
              resolve(r);
            })
            .catch((r) => {
              importStatusDispatch({ type: 'ERROR', value: idx });
              reject(r);
            });
        });
      } else if (data[_IMPORT_TYPE_KEY] === ImportTypeEnum.UPDATE) {
        return new Promise((resolve, reject) => {
          updateHandler(data)
            .then((r) => {
              importStatusDispatch({ type: 'COMPLETE', value: idx });
              resolve(r);
            })
            .catch((r) => {
              importStatusDispatch({ type: 'ERROR', value: idx });
              reject(r);
            });
        });
      } else {
        return new Promise((resolve, reject) => {
          deleteHandler(data)
            .then((r) => {
              importStatusDispatch({ type: 'COMPLETE', value: idx });
              resolve(r);
            })
            .catch((r) => {
              importStatusDispatch({ type: 'ERROR', value: idx });
              reject(r);
            });
        });
      }
    },
    [importStatusDispatch, createHandler, updateHandler, deleteHandler]
  );

  /**
   * インポート処理の実行
   * @param importData 構造化データ
   * @returns エラーメッセージ
   */
  const executeAll = useCallback(
    (importData: TFormValues[]) => {
      setProcessStatus(ProcessStatus.IMPORTING);
      importStatusDispatch({ type: 'RESET', value: 0 });
      const queue = new PromiseQueue(maxConcurrent);
      importData.forEach((data, idx) => {
        importStatusDispatch({ type: 'READY', value: idx });
        queue
          .add(() => _execute(data, idx))
          .then(() => {
            if (queue.getPendingLength() === 0) {
              setProcessStatus(ProcessStatus.IMPORTED);
              onCompleted && onCompleted();
            }
          });
      });
    },
    [_execute, importStatusDispatch, maxConcurrent, onCompleted]
  );

  /**
   * アップロードファイルからExcelブックの読み込み
   * @param files アップロードファイル
   */
  const onDrop = useCallback(
    (files: File[]) => {
      reset();
      setProcessStatus(ProcessStatus.UPLOADING);
      const reader = new FileReader();
      reader.readAsArrayBuffer(files[0]);
      reader.onloadend = () => {
        const arrayBuffer = reader.result as ExcelJS.Buffer;

        if (arrayBuffer) {
          const workbook = new ExcelJS.Workbook();

          const importProcess = (sheet: ExcelJS.Worksheet) => {
            const { importData, raw, columns } = buildData(sheet, mapping);
            setImportData(raw);
            setColumns(columns);

            const errors = validation(importData as TFormValues[]);
            if (errors.length > 0) {
              setProcessStatus(ProcessStatus.VALIDATIONERROR);
              setErrors(errors);
            } else {
              executeAll(importData);
            }
          };
          void workbook.xlsx
            .load(arrayBuffer)
            .then((book) => importProcess(book.worksheets[0]))
            .catch(() => {
              // エラーハンドリング
              // ファイルアップロードエラー
            });
        }
      };
    },
    [executeAll, mapping, reset, validation]
  );

  return (
    <>
      <Button
        type="button"
        variant="secondary"
        onClick={() => {
          setShow(true);
        }}
      >
        インポート
      </Button>
      <Modal
        size={
          processStatus === ProcessStatus.IMPORTING ||
          processStatus === ProcessStatus.IMPORTED ||
          processStatus === ProcessStatus.VALIDATIONERROR
            ? 'xl'
            : 'lg'
        }
        backdrop={'static'}
        show={show}
        onHide={() => {
          if (
            !(
              processStatus === ProcessStatus.UPLOADING || processStatus === ProcessStatus.IMPORTING
            )
          ) {
            onClosed && onClosed();
            setShow(false);
            setProcessStatus(ProcessStatus.NONE);
          }
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{title ? title : 'インポート'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {processStatus === ProcessStatus.NONE ? <Dropzone onDrop={onDrop} /> : ''}
          {processStatus === ProcessStatus.UPLOADING ? (
            <Spinner size="sm" className="text-current fixed mt-1" />
          ) : (
            ''
          )}
          {processStatus === ProcessStatus.IMPORTING ||
          processStatus === ProcessStatus.IMPORTED ||
          processStatus === ProcessStatus.VALIDATIONERROR ? (
            <ImportGrid
              itemsSource={importData}
              columns={columns}
              validationErrors={errors}
              importStatus={importStatus}
            />
          ) : (
            ''
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export enum BillSendDivisionEnum {
  ELECTRONIC = '1',
  MAIL = '2',
  WEB_SYSTEM = '3',
}

export const BillSendDivision = [
  { label: '郵送', value: BillSendDivisionEnum.MAIL },
  { label: 'メール', value: BillSendDivisionEnum.ELECTRONIC },
  { label: 'Webシステム', value: BillSendDivisionEnum.WEB_SYSTEM },
];

export enum DemandClosingStatusCodeEnum {
  // 締切状態コード
  CLOSING_BEFORE = '1',
  CLOSING_AFTER = '2',
}

export const DemandClosingStatusCode = [
  { label: '請求前', value: DemandClosingStatusCodeEnum.CLOSING_BEFORE },
  { label: '請求済', value: DemandClosingStatusCodeEnum.CLOSING_AFTER },
];

import { CollectionView } from '@grapecity/wijmo';
import { FlexGrid as FG } from '@grapecity/wijmo.grid';
import { FlexGrid, FlexGridColumn } from '@grapecity/wijmo.react.grid';
import { useCallback, useEffect, useState } from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';

import { ImportStatus } from '../lib/useImportStatus';

export type HeaderColumn = {
  binding: string;
  header: string;
};

export const _IMPORT_RESULT_KEY = '_importResult';

type ImportGridProps = {
  itemsSource: any[];
  validationErrors: any[];
  columns: HeaderColumn[];
  importStatus: ImportStatus;
  progressbar?: boolean;
};

export const ImportGrid = ({
  itemsSource,
  validationErrors,
  columns,
  importStatus,
  progressbar,
}: ImportGridProps) => {
  const [collectionView, setCollectionView] = useState<CollectionView>();

  const getError = useCallback(
    (item: any, propName: any) => {
      if (validationErrors[item._idx] && validationErrors[item._idx][propName]) {
        return validationErrors[item._idx][propName][0];
      }
      return null;
    },
    [validationErrors]
  );

  useEffect(() => {
    const newCollectionView = new CollectionView(
      itemsSource.map((d, idx) => {
        d['_idx'] = idx;
        d['_no'] = idx + 1;
        if (validationErrors[idx]) d[_IMPORT_RESULT_KEY] = '検証エラー';
        if (importStatus.processings.includes(idx)) d[_IMPORT_RESULT_KEY] = '取込中';
        if (importStatus.completes.includes(idx)) d[_IMPORT_RESULT_KEY] = '取込完了';
        if (importStatus.errors.includes(idx)) d[_IMPORT_RESULT_KEY] = '取込失敗';
        return d;
      }),
      {
        getError: getError,
      }
    );
    setCollectionView(newCollectionView);
  }, [itemsSource, validationErrors, importStatus, getError]);

  return (
    <>
      {progressbar ? (
        <ProgressBar animated>
          <ProgressBar
            variant="success"
            now={(importStatus.completes.length / itemsSource.length) * 100}
            key={1}
          />
          <ProgressBar
            variant="danger"
            now={(importStatus.errors.length / itemsSource.length) * 100}
            key={3}
          />
        </ProgressBar>
      ) : (
        ''
      )}
      <div className="mt-3" />
      <FlexGrid
        style={{ maxHeight: 'calc(100vh - 200px)' }}
        itemsSource={collectionView}
        initialized={(s: FG) => s.autoSizeColumns()}
        loadedRows={(s: FG) => s.autoSizeColumns()}
      >
        <FlexGridColumn header="処理結果" binding="_importResult" isReadOnly />
        <FlexGridColumn header="No." binding="_no" isReadOnly />
        {columns.map((c) => (
          // eslint-disable-next-line react/jsx-key
          <FlexGridColumn header={c.header} binding={c.binding} isReadOnly />
        ))}
      </FlexGrid>
    </>
  );
};

import { To } from 'history';
import { NavLink, NavLinkProps } from 'react-router-dom';

import { BookmarkButton } from './BookmarkButton';

type MenuLinkProps = NavLinkProps & {
  onBookmark: (to: To, actived: boolean) => void;
  active: boolean;
};

export const MenuLink = ({ to, children, end, onBookmark, active }: MenuLinkProps) => {
  return (
    <>
      <li className="sidebar-item sidebar-link-item">
        <NavLink to={to} end={end} className="sidebar-link" tabIndex={-1}>
          {children}
        </NavLink>
        <BookmarkButton
          active={active}
          onClick={(actived) => {
            onBookmark(to, actived);
          }}
        />
      </li>
    </>
  );
};

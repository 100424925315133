/* eslint-disable @typescript-eslint/no-unused-vars */
import { gql } from 'graphql-request';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import Select, { SingleValue } from 'react-select';

import { graphqlClient } from '@/fetcher/fetcher';
import { Agent, Maybe, Printer } from '@/generated/graphql';
import { edgesToPlainList } from '@/utils/graphqlHelper';

import { Spinner } from '../Spinner';

import { getDefaultPrinter } from './defaultPrinter';
import { PrinterIndicator } from './PrinterIndicator';

interface TrayOption {
  readonly label: string;
  readonly value: string;
}

interface PrinterOption {
  readonly value: string;
  readonly label: string;
  readonly state: string;
  readonly stateUpdated: string;
  readonly trays: TrayOption[];
}

interface AgentOption {
  readonly label: string;
  readonly options: readonly PrinterOption[];
}

const query = gql`
  query {
    loginUserAgents {
      edges {
        node {
          id
          name
          agentId
          platform
          ipAddress
          lastModified
          search
          printers {
            id
            printerId
            name
            key
            stateUpdated
            name
            label
            manufacturer
            reachable
            ipAddress
            description
            pdlList
            preferences
            state
            localPrinter
            trays {
              id
              trayId
              name
            }
          }
        }
      }
    }
  }
`;

const loadAgentPrinter = () => {
  return graphqlClient.request(query);
};

const formatGroupLabel = (data: AgentOption) => (
  <div>
    <span>{data.label}</span>
    <span className="ml-2">[{data.options.length}]</span>
  </div>
);

const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  if (date) {
    return `${date.getFullYear()}/${
      date.getMonth() + 1
    }/${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
  } else {
    return '';
  }
};

const formatOptionLabel = (data: PrinterOption) => (
  <div>
    <span>{data.label}</span>
    <span className="ml-5" />
    <PrinterIndicator state={data.state} />
    <span className="ml-5" />
    <span>(更新日時: {formatDate(data.stateUpdated)})</span>
  </div>
);

type PrinterListProps = {
  onChange: (printer: string, tray: string | undefined) => void;
};

export const PrinterList = ({ onChange }: PrinterListProps) => {
  const [options, setOptions] = useState<AgentOption[]>();
  const [trayOptions, setTrayOptions] = useState<TrayOption[]>([]);

  const [selectedPrinter, setSelectedPrinter] = useState<Maybe<PrinterOption>>();
  const [selectedTray, setSelectedTray] = useState<Maybe<TrayOption>>();

  const [printerValue, setPrinterValue] = useState<SingleValue<PrinterOption> | undefined>(
    undefined
  );
  const [trayValue, setTrayValue] = useState<SingleValue<TrayOption> | undefined>(undefined);

  useEffect(() => {
    loadAgentPrinter().then((d) => {
      const list = edgesToPlainList(_.chain(d).get('loginUserAgents.edges').value());

      const options = list.map((a: Agent): AgentOption => {
        let printers: PrinterOption[] = [];
        if (a?.printers) {
          printers = a.printers.map((p): PrinterOption => {
            let trays: TrayOption[] = [];
            if (p?.trays)
              trays = p.trays.map((t) => {
                return {
                  label: t?.name ? t.name : '',
                  value: t?.trayId ? t.trayId : '',
                };
              });
            return {
              label: p?.label ? p?.label : p?.name ?? '',
              value: p?.printerId ? p.printerId : '',
              state: p?.state ? p.state : '',
              stateUpdated: p?.stateUpdated ? p.stateUpdated : '',
              trays: trays,
            };
          });
        }
        const agent: AgentOption = {
          label: a.name as string,
          options: printers,
        };
        return agent;
      });
      setOptions(options);

      const defaultPrinter = getDefaultPrinter();

      options.forEach((a) => {
        a.options.forEach((p) => {
          if (p.value === defaultPrinter?.printer) {
            setTrayOptions(p?.trays as TrayOption[]);
            setPrinterValue(p);
            p?.trays.forEach((t) => {
              if (t.value === defaultPrinter?.trayId) {
                setTrayValue(t);
                setSelectedPrinter(p);
                setSelectedTray(t);
              }
            });
          }
        });
      });
    });
  }, []);

  useEffect(() => {
    if (selectedPrinter) {
      onChange(selectedPrinter.value, selectedTray?.value);
    }
  }, [onChange, selectedPrinter, selectedTray]);

  return (
    <>
      {!options && (
        <>
          <div>プリンター情報取得中....</div>
          <Spinner />
        </>
      )}
      {options && (
        <>
          <h5>プリンタ</h5>
          <Select<PrinterOption, false, AgentOption>
            options={options}
            formatGroupLabel={formatGroupLabel}
            formatOptionLabel={formatOptionLabel}
            onChange={(opt) => {
              setPrinterValue(opt);
              setTrayValue(opt?.trays[0]);
              setSelectedPrinter(opt);
              setSelectedTray(opt?.trays[0]);
              setTrayOptions(opt?.trays as TrayOption[]);
            }}
            value={printerValue}
          />
          <div className="mt-4" />
          <h5>給紙トレイ</h5>
          <Select<TrayOption>
            options={trayOptions}
            onChange={(opt) => {
              setTrayValue(opt);
              setSelectedTray(opt);
            }}
            value={trayValue}
          />
        </>
      )}
    </>
  );
};

export const UnitCode = [
  {
    label: '個',
    value: '01',
  },
  {
    label: '箱',
    value: '02',
  },
  {
    label: '枚',
    value: '03',
  },
  {
    label: '件',
    value: '04',
  },
  {
    label: '冊',
    value: '05',
  },
  {
    label: 'box',
    value: '06',
  },
  {
    label: '本',
    value: '07',
  },
  {
    label: '足',
    value: '08',
  },
];

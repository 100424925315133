import { STAGE } from '@/config';

import { menus } from '../data/MenuData';
import { MenuLinkParam } from '../types';

export const getBookmarkMenu = (bookmark: string[]) => {
  const bookmarkMenu: MenuLinkParam[] = [];
  menus.forEach((group) => {
    if (!(STAGE === 'DEMO' && group.disabled)) {
      group.menus.forEach((link) => {
        if (!(STAGE === 'DEMO' && link.disabled)) {
          if (bookmark.includes(link.to.toString())) {
            bookmarkMenu.push(link);
          }
        }
      });
    }
  });
  return bookmarkMenu;
};

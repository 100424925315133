import { ChangeEvent, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

type RedirectSelectProps = {
  children: React.ReactElement[];
};

export const RedirectSelect = ({ children }: RedirectSelectProps) => {
  const navigate = useNavigate();

  const changeHandler = useCallback(
    (evt: ChangeEvent<HTMLSelectElement>) => {
      const current = children.filter((c) => {
        return c.props.navigate === evt.currentTarget.value;
      });
      current[0] && navigate(current[0].props.navigate);
    },
    [children, navigate]
  );

  const blurHandler = useCallback(() => {}, []);

  return (
    <>
      <select className="form-select" onChange={changeHandler} onBlur={blurHandler}>
        {children}
      </select>
    </>
  );
};

export enum TradeHistoryTypeEnum {
  // 取引実績種別コード
  PURCHASE = '01',
  BUY_RETURN = '02',
  DIRECT_RETURN = '03',
  TRUST_RETURN = '04',
  DIVISION_INPUT = '05',
  PRODUCTION_INPUT = '06',
  ADJUST_INPUT = '07',
  BUY_OUTPUT = '08',
  DIRECT_OUTPUT = '09',
  TRUST_OUTPUT = '10',
  PURCHASE_RETURN = '11',
  DIVISION_OUTPUT = '12',
  PRODUCTION_OUTPUT = '13',
  DISPOSAL = '14',
  ADJUST_OUTPUT = '15',
  TRUST_INPUT = '16',
  TRUST_RETURN_OUTPUT = '17',
  // DAMEGED_RETURN = "18"  // 受注/出荷-破損返品
  // ARRIVED_CHANGED = "19"  // 発注/入荷-入荷時交換 入荷時交換は無し。
  LATER_CHANGED = '20',
  TRUST_INVENTORY = '21',
  TRUST_DISPOSAL = '22',
  INVENTORY = '23',
  DAMEGED_RETURN_DISPOSAL = '24',
  TRUST_WAREHOUSE_INPUT = '25',
  TRUST_WAREHOUSE_OUTPUT = '26',
  PURCHASE_RETURN_DISPOSAL = '27',
  CHANGED_PURCHASE = '28',
  INVENTORY_OUTPUT = '29',
  TRUST_SALESE = '30',
  WITHIN_COMPANY_INPUT = '31',
  WITHIN_COMPANY_OUTPUT = '32',
  WITHIN_COMPANY_RETURN_INPUT = '33',
  WITHIN_COMPANY_RETURN_OUTPUT = '34',
  TRANSFER_JAN_OUTPUT = '35',
  TRANSFER_JAN_INPUT = '36',
  TRANSFER_JAN_DIVISION_OUTPUT = '37',
  TRANSFER_JAN_DIVISION_INPUT = '38',
  WITHIN_COMPANY_WAREHOUSE_OUTPUT = '39',
  WITHIN_COMPANY_WAREHOUSE_INPUT = '40',
}

export const TradeHistoryType = [
  { label: '発注/入荷-仕入', value: TradeHistoryTypeEnum.PURCHASE },
  { label: '受注/出荷-買取返品', value: TradeHistoryTypeEnum.BUY_RETURN },
  { label: '受注/出荷-直営返品', value: TradeHistoryTypeEnum.DIRECT_RETURN },
  { label: '受注/出荷-委託返品', value: TradeHistoryTypeEnum.TRUST_RETURN },
  { label: '倉庫移動-部門倉庫入庫', value: TradeHistoryTypeEnum.DIVISION_INPUT },
  { label: '生産-生産入庫', value: TradeHistoryTypeEnum.PRODUCTION_INPUT },
  { label: '在庫調整-調整入庫', value: TradeHistoryTypeEnum.ADJUST_INPUT },
  { label: '受注/出荷-買取出荷', value: TradeHistoryTypeEnum.BUY_OUTPUT },
  { label: '受注/出荷-直営出荷', value: TradeHistoryTypeEnum.DIRECT_OUTPUT },
  { label: '受注/出荷-委託出庫', value: TradeHistoryTypeEnum.TRUST_OUTPUT },
  { label: '発注/入荷-仕入返品', value: TradeHistoryTypeEnum.PURCHASE_RETURN },
  { label: '倉庫移動-部門倉庫出庫', value: TradeHistoryTypeEnum.DIVISION_OUTPUT },
  { label: '生産-生産出庫', value: TradeHistoryTypeEnum.PRODUCTION_OUTPUT },
  { label: '在庫調整-破損', value: TradeHistoryTypeEnum.DISPOSAL },
  { label: '在庫調整-調整出庫', value: TradeHistoryTypeEnum.ADJUST_OUTPUT },
  { label: '受注/出荷-委託入庫', value: TradeHistoryTypeEnum.TRUST_INPUT },
  { label: '受注/出荷-委託返品出庫', value: TradeHistoryTypeEnum.TRUST_RETURN_OUTPUT },
  { label: '発注/入荷-後日交換', value: TradeHistoryTypeEnum.LATER_CHANGED },
  { label: '委託棚卸-委託棚卸', value: TradeHistoryTypeEnum.TRUST_INVENTORY },
  { label: '委託棚卸-委託廃棄', value: TradeHistoryTypeEnum.TRUST_DISPOSAL },
  { label: '棚卸-棚卸', value: TradeHistoryTypeEnum.INVENTORY },
  { label: '受注/出荷-破損返品の破棄', value: TradeHistoryTypeEnum.DAMEGED_RETURN_DISPOSAL },
  { label: '倉庫移動-委託倉庫入庫', value: TradeHistoryTypeEnum.TRUST_WAREHOUSE_INPUT },
  { label: '倉庫移動-委託倉庫出庫', value: TradeHistoryTypeEnum.TRUST_WAREHOUSE_OUTPUT },
  { label: '発注/入荷-破損返品', value: TradeHistoryTypeEnum.PURCHASE_RETURN_DISPOSAL },
  { label: '発注/入荷-交換分の入荷', value: TradeHistoryTypeEnum.CHANGED_PURCHASE },
  { label: '棚卸-棚卸/棚卸出庫', value: TradeHistoryTypeEnum.INVENTORY_OUTPUT },
  { label: '委託売上', value: TradeHistoryTypeEnum.TRUST_SALESE },
  { label: '受注/社内間入庫', value: TradeHistoryTypeEnum.WITHIN_COMPANY_INPUT },
  { label: '受注/社内間出庫', value: TradeHistoryTypeEnum.WITHIN_COMPANY_OUTPUT },
];

import {
  CognitoUserPool,
  AuthenticationDetails,
  CognitoUser,
  CognitoUserSession,
  IAuthenticationCallback,
} from 'amazon-cognito-identity-js';
import * as AWS from 'aws-sdk/global';
import { gql } from 'graphql-request';

import { AWS_REGION, COGNITO_CLIENT_ID, COGNITO_USERPOOL_ID } from '@/config';
import { graphqlClient } from '@/fetcher/fetcher';

import { AuthUser, LoginCredentialsDTO, RegisterCredentialsDTO, TokenResponse } from '..';
import { NewPasswordRequired } from '../exceptions/NewPasswordRequired';

const poolData = {
  UserPoolId: COGNITO_USERPOOL_ID,
  ClientId: COGNITO_CLIENT_ID,
};

AWS.config.region = AWS_REGION;

export const authenticateUser = (data: LoginCredentialsDTO): Promise<TokenResponse> => {
  const userPool = new CognitoUserPool(poolData);

  const userData = {
    Username: data.userId,
    Pool: userPool,
  };

  const authenticationData = {
    Username: data.userId,
    Password: data.password,
  };

  const authenticationDetails = new AuthenticationDetails(authenticationData);

  const cognitoUser = new CognitoUser(userData);

  return new Promise((resolve, reject) => {
    const callbacks: IAuthenticationCallback = {
      onSuccess: (result: CognitoUserSession) => {
        result.getRefreshToken().getToken();
        const idToken = result.getIdToken().getJwtToken();
        const refreshToken = result.getRefreshToken().getToken();

        resolve({
          idToken: idToken,
          refreshToken: refreshToken,
        });
      },
      onFailure: (err: any) => {
        reject(err);
      },
      newPasswordRequired: (userAttributes: any) => {
        reject(new NewPasswordRequired(cognitoUser, userAttributes));
      },
    };
    cognitoUser.authenticateUser(authenticationDetails, callbacks);
  });
};

export const refreshToken = (): Promise<void> => {
  return {} as any;
};

export const getCurrentUser = (): CognitoUser | null => {
  const userPool = new CognitoUserPool(poolData);
  return userPool.getCurrentUser();
};

export const getJWTToken = (): Promise<string> => {
  return new Promise((resolve, reject) => {
    const currentUser = getCurrentUser();
    if (currentUser != null) {
      currentUser.getSession((err: Error, session: CognitoUserSession | null) => {
        if (err) {
          reject(new Error('COGNITO_AUTH_ERROR'));
          return;
        }
        if (session) {
          if (session.isValid()) {
            const idToken = session.getIdToken().getJwtToken();
            resolve(idToken);
            return;
          } else {
            const refresh_token = session.getRefreshToken();
            currentUser.refreshSession(refresh_token, (err, newSession) => {
              if (err) {
                reject(err);
                return;
              }
              if (newSession) {
                const idToken = newSession.getIdToken().getJwtToken();
                resolve(idToken);
                return;
              }
            });
          }
        } else {
          reject(new Error('COGNITO_AUTH_ERROR'));
        }
      });
    } else {
      reject(new Error('COGNITO_AUTH_ERROR'));
    }
  });
};

export const logout = () => {
  return new Promise<void>((success) => {
    const userPool = new CognitoUserPool(poolData);
    const cognitoUser = userPool.getCurrentUser();
    if (cognitoUser !== null) {
      cognitoUser.signOut();
    }
    success();
  });
};

const query = gql`
  query getLoginUser($id: ID!) {
    user(id: $id) {
      id
      userCode
      userName
      userType
      divisionCode
      subDivision1
      subDivision2
      email
      systemAdminFlg
      division {
        divisionName
      }
    }
  }
`;
export const getLoginUser = (): Promise<AuthUser> => {
  return new Promise((resolve, reject) => {
    const currentUser = getCurrentUser();
    if (currentUser) {
      const id = window.btoa('User:' + currentUser.getUsername());
      graphqlClient
        .request(query, { id: id })
        .then((data) => {
          const divisionCodes = [];
          if (data['user']['divisionCode']) divisionCodes.push(data['user']['divisionCode']);
          if (data['user']['subDivision1']) divisionCodes.push(data['user']['subDivision1']);
          if (data['user']['subDivision2']) divisionCodes.push(data['user']['subDivision2']);
          resolve({
            id: data['user']['id'],
            userCode: data['user']['userCode'],
            userName: data['user']['userName'],
            userType: data['user']['userType'],
            divisionCode: data['user']['divisionCode'],
            subDivision1: data['user']['subDivision1'],
            subDivision2: data['user']['subDivision2'],
            divisionCodes: divisionCodes,
            divisionName: data['user']['division'] ? data['user']['division']['divisionName'] : '',
            role: data['user']['systemAdminFlg'] === '1' ? 'ADMIN' : 'USER',
            email: data['user']['email'],
          });
        })
        .catch((e) => reject(e));
    } else {
      reject(new Error('NOT LOGIN'));
    }
  });
};

export const registerUser = (data: RegisterCredentialsDTO): Promise<any> => {
  return new Promise((resolve, reject) => {
    const callbacks: IAuthenticationCallback = {
      onSuccess: (result) => {
        result.getRefreshToken().getToken();
        const idToken = result.getIdToken().getJwtToken();
        const refreshToken = result.getRefreshToken().getToken();

        resolve({
          idToken: idToken,
          refreshToken: refreshToken,
        });
      },
      onFailure: (err: any) => {
        reject(err);
      },
    };
    data.cognitoUser.completeNewPasswordChallenge(data.newPassword, undefined, callbacks);
  });
};
export const changePassword = (
  cognitoUser: CognitoUser,
  userAttributes: any,
  newPassword: string
) => {
  cognitoUser.completeNewPasswordChallenge(newPassword, userAttributes, {
    onSuccess: (result) => {
      // login
      console.log(result);
    },
    onFailure: (result) => {
      console.log(result);
    },
  });
};

export enum ShippingTypeCodeEnum {
  /** 自社 */ IN_HOUSE = '1',
  /** 得意先 */ CUSTOMER = '2',
  /** 納入先 */ DELIVER = '3',
  /** 外部倉庫 */ EXTERNAL_WAREHOUSE = '4',
  /** 手入力 */ MANUAL = '5',
}

export const ShippingTypeCode = [
  { label: '自社', value: ShippingTypeCodeEnum.IN_HOUSE },
  { label: '得意先', value: ShippingTypeCodeEnum.CUSTOMER },
  { label: '納入先', value: ShippingTypeCodeEnum.DELIVER },
  { label: '外部倉庫', value: ShippingTypeCodeEnum.EXTERNAL_WAREHOUSE },
  { label: '手入力', value: ShippingTypeCodeEnum.MANUAL },
];

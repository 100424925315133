export enum CustomerGroupEnum {
  NORMAL = '1',
  OUTSOURCER = '2',
  DIRECT = '3',
}

export const CustomerGroup = [
  { label: '買取先', value: CustomerGroupEnum.NORMAL },
  { label: '委託先', value: CustomerGroupEnum.OUTSOURCER },
  { label: '直営店', value: CustomerGroupEnum.DIRECT },
];

export type PrinterObj = {
  printer: string;
  trayId: string | undefined;
};

export const saveDefaultPrinter = (printer: string, trayId: string) => {
  const printerObj: PrinterObj = {
    printer,
    trayId,
  };
  localStorage.setItem('defaultPrinter', JSON.stringify(printerObj));
};

export const getDefaultPrinter = (): PrinterObj | undefined => {
  try {
    const printer = localStorage.getItem('defaultPrinter');
    return printer ? JSON.parse(printer) : undefined;
  } catch (e) {
    localStorage.removeItem('defaultPrinter');
    return undefined;
  }
};

import clsx from 'clsx';
import { useContext } from 'react';

import { CollapseContext } from '.';

type ControlAreaProps = {
  children: React.ReactNode;
};

export const ControlArea = ({ children }: ControlAreaProps) => {
  const isCollapse = useContext(CollapseContext);
  return (
    <>
      <div>
        <div className={clsx('control-area', isCollapse ? 'collapsed' : '')}>{children}</div>
        <div className="control-area-space" />
      </div>
    </>
  );
};

import dayjs from 'dayjs';
import ExcelJS from 'exceljs';

import { IMPORT_TYPE_KEY, _IMPORT_TYPE_KEY } from '@/business_code';

import { HeaderColumn } from '../Import/ImportGrid';

type ImportDataType = 'String' | 'Int' | 'Float' | 'Date' | 'Code' | 'Mail';

export type ImportMapping<TFormValues> = {
  header: string;
  binding: keyof TFormValues;
  dataType: ImportDataType;
  format?: string;
  dataMap?: { label: string; value: any }[];
};

export type ImportErrors = {
  [k: string]: string[];
};

/**
 * 構造化データの作成
 * @param book Excelブック
 * @returns 構造化データ
 */
export const buildData = <TFormValues>(
  sheet: ExcelJS.Worksheet,
  mapping: ImportMapping<TFormValues>[],
  headerRowNum = 1,
  hasTotalRow = false
): { importData: TFormValues[]; raw: { [key: string]: any }[]; columns: HeaderColumn[] } => {
  // 処理結果描画用
  const columns: HeaderColumn[] = [];
  const raw: { [key: string]: any }[] = [];

  // ヘッダー行の抽出
  const headerData: string[] = [];
  let importTypeCol: number | null = null;
  sheet.getRow(headerRowNum).eachCell((c, n) => {
    const value = c.formula ? String(c.result) : String(c.value);
    const key = headerData.includes(value) ? value + String(n) : value;
    if (key === IMPORT_TYPE_KEY) importTypeCol = n;
    const mapper = mapping.find((m) => m.header === key);
    const newKey = mapper?.binding ?? key;
    headerData.push(newKey as string);
    columns.push({
      header: value,
      binding: newKey as string,
    });
  });

  // データ行の抽出
  const importData: { [key: string]: any }[] = [];
  sheet.eachRow((row, rowNumber) => {
    if (rowNumber <= headerRowNum) return;
    if (hasTotalRow && rowNumber === sheet.actualRowCount) return;

    const rowData: any = {};
    const rawData: any = { _IMPORT_RESULT_KEY: '' };
    row.eachCell((c, n) => {
      const mapper = mapping.find((m) => m.binding === headerData[n - 1]);
      const value = c.formula ? c.result : c.value;
      if (mapper) {
        const { convertValue } = convertData(value, mapper.dataType, mapper.dataMap);
        rowData[mapper.binding] = convertValue;
        if (mapper.dataType === 'Date') {
          rawData[headerData[n - 1]] = convertDate(value);
        } else if (mapper.dataType === 'String' || mapper.dataType === 'Mail') {
          rawData[headerData[n - 1]] = convertValue;
        } else {
          rawData[headerData[n - 1]] = value;
        }
      }
      if (importTypeCol === n) {
        rowData[_IMPORT_TYPE_KEY] = value;
        rawData[IMPORT_TYPE_KEY] = value;
      }
    });
    importData.push(rowData);
    raw.push(rawData);
  });

  return { importData: importData as TFormValues[], raw: raw, columns: columns };
};

const convertData = (
  value: any,
  dataType: ImportDataType,
  dataMap?: { label: string; value: any }[]
): { convertValue: string | number | Date; error: string[] } => {
  let convertValue = value;
  const error: string[] = [];
  if (dataType === 'String') {
    convertValue = String(value);
  }
  if (dataType === 'Int') {
    convertValue = parseInt(value);
    if (isNaN(convertValue)) {
      error.push('数値を入力してください。');
      convertValue = value;
    }
  }
  if (dataType === 'Float') {
    convertValue = parseFloat(value);
    if (isNaN(convertValue)) {
      error.push('数値を入力してください。');
      convertValue = value;
    }
  }
  if (dataType === 'Date') {
    if (value instanceof Date) {
      convertValue = dayjs(value).format('YYYY-MM-DD');
    } else if (typeof value === 'number') {
      const newDate = new Date(1900, 0, 1);
      newDate.setDate(value - 1);
      convertValue = dayjs(newDate).format('YYYY-MM-DD');
    } else {
      error.push('日付を入力してください。');
      convertValue = value;
    }
  }
  if (dataType === 'Code') {
    const code = dataMap?.find((d) => d.label === value);
    if (code) {
      convertValue = code.value;
    } else {
      error.push('コードが存在しません。');
      convertValue = undefined;
    }
  }
  if (dataType === 'Mail') {
    if (value?.hyperlink) {
      convertValue = value.text;
    }
  }
  return { convertValue, error };
};

const convertDate = (value: any) => {
  if (value instanceof Date) {
    return value;
  } else if (typeof value === 'number') {
    const newDate = new Date(1900, 0, 1);
    newDate.setDate(value - 1);
    return newDate;
  } else {
    return value;
  }
};

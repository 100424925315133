import _ from 'lodash';
import { useCallback, MouseEvent as ReactMouseEvent } from 'react';
import { FormState } from 'react-hook-form';

import { Button, ButtonProps } from './Button';

type GuardButtonProps<TFieldValues> = ButtonProps & {
  formState: FormState<TFieldValues>;
  message?: string;
  guardIgnores?: (keyof TFieldValues)[];
};

const defaultMessage = '編集内容がリセットされます、本当に実行しますか？';

export const GuardButton = <TFieldValues,>({
  formState,
  message = defaultMessage,
  guardIgnores = [],
  onClick,
  ...props
}: GuardButtonProps<TFieldValues>) => {
  const guardOnClick = useCallback(
    (event: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (formState.isDirty && !_.isEmpty(_.omit(formState.dirtyFields, guardIgnores))) {
        const answer = window.confirm(message);
        if (answer) {
          if (onClick) onClick(event);
        }
      } else {
        if (onClick) onClick(event);
      }
    },
    [formState.dirtyFields, formState.isDirty, guardIgnores, message, onClick]
  );

  return (
    <>
      <Button {...props} onClick={guardOnClick} />
    </>
  );
};

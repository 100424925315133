export enum ApprovalStatusEnum {
  UNAPPROVED = '0', // 未承認
  AGREEMENT = '1', // 承認
  REJECTION = '2', // 否決
  CHANGED = '3', // 変更
}

export const ApprovalStatus = [
  { label: '未承認', value: ApprovalStatusEnum.UNAPPROVED },
  { label: '承認', value: ApprovalStatusEnum.AGREEMENT },
  { label: '否決', value: ApprovalStatusEnum.REJECTION },
  { label: '変更', value: ApprovalStatusEnum.CHANGED },
];

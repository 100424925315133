import { Globalize } from '@grapecity/wijmo';
import * as grid from '@grapecity/wijmo.grid';
import { FlexGridColumn } from '@grapecity/wijmo.react.grid';
import React from 'react';
import { Offcanvas } from 'react-bootstrap';

import { Button } from '@/components/Elements';
import { BaseFlexGridDialog } from '@/components/Wijmo/FlexGrid/BaseFlexGridDialog';

import { ExportMapping } from './ExportData';

type MappingFilterProps = {
  show: boolean;
  onHide: () => void;
  mapping: ExportMapping[];
  onFiltered: (mapping: ExportMapping[]) => void;
};

type MappingFilterState = {
  filteredMapping: ExportMapping[];
  flexGrid: grid.FlexGrid | undefined;
};

type SelectedExportMapping = ExportMapping & { selected: boolean };

export class MappingFilter extends React.Component<MappingFilterProps, MappingFilterState> {
  gridRef: React.RefObject<HTMLDivElement>;
  constructor(props: MappingFilterProps) {
    super(props);
    this.state = {
      filteredMapping: this.props.mapping.map((m) => ({ ...m, selected: true })),
      flexGrid: undefined,
    };
    this.gridRef = React.createRef<HTMLDivElement>();
  }

  componentDidUpdate(prevProps: MappingFilterProps) {
    if (prevProps.mapping !== this.props.mapping) {
      this.setState({ filteredMapping: this.props.mapping.map((m) => ({ ...m, selected: true })) });
    }
  }

  initialized(flexGrid: grid.FlexGrid): void {
    this.setState({ flexGrid: flexGrid });
  }

  changeAllSelected(check: boolean): void {
    this.setState((prev) => {
      const filter = prev.filteredMapping.map((item) => {
        item.selected = check;
        return item;
      });
      return {
        filteredMapping: filter,
      };
    });
  }

  render() {
    return (
      <>
        <Offcanvas
          show={this.props.show}
          onHide={this.props.onHide}
          placement="end"
          className="fk__offcanvas-xsmall"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title className="fk__heading__contents">出力項目設定</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body ref={this.gridRef}>
            <div className="fk__btn-group mb-3">
              <>
                <Button variant="secondary" onClick={() => this.changeAllSelected(true)}>
                  すべて選択
                </Button>
                <Button variant="secondary" onClick={() => this.changeAllSelected(false)}>
                  選択解除
                </Button>
                <div className="text-end fk__btn-group__float-right">
                  {`選択済み ${Globalize.format(
                    this.state.flexGrid?.itemsSource?.reduce((r: number, c: ExportMapping) => {
                      if (c.selected) r += 1;
                      return r;
                    }, 0),
                    'n'
                  )}／${Globalize.format(this.state.flexGrid?.itemsSource?.length, 'n')}`}
                </div>
              </>
            </div>
            <BaseFlexGridDialog
              initialized={this.initialized.bind(this)}
              itemsSource={this.state.filteredMapping}
              targetViewRef={this.gridRef}
            >
              <FlexGridColumn binding="selected" header=" " dataType="Boolean" width={50} />
              <FlexGridColumn binding="header" header="項目名" width="*" />
            </BaseFlexGridDialog>
          </Offcanvas.Body>
          <div className="bottom-0 start-0 end-0">
            <div className="fk__offcanvas-set-btn">
              <Button
                onClick={() => {
                  if (!this.state.flexGrid) return;
                  const filtered = this.state.flexGrid.itemsSource.filter(
                    (item: SelectedExportMapping) => item.selected
                  );
                  this.props.onFiltered(filtered);
                  this.props.onHide();
                }}
              >
                確定
              </Button>
            </div>
          </div>
        </Offcanvas>
      </>
    );
  }
}

import { useReducer } from 'react';

type ImportStatusAction = {
  type: 'READY' | 'PROCESSING' | 'COMPLETE' | 'ERROR' | 'RESET';
  value: number;
};

export type ImportStatus = {
  ready: number[];
  processings: number[];
  completes: number[];
  errors: number[];
};

export const useImportStatus = () => {
  const init = (initialState: number[]) => {
    return {
      ready: initialState,
      processings: [],
      completes: [],
      errors: [],
    };
  };
  const reducer = (state: ImportStatus, action: ImportStatusAction) => {
    switch (action.type) {
      case 'READY':
        return {
          ready: [...state.ready, action.value],
          processings: state.processings,
          completes: state.completes,
          errors: state.errors,
        };
      case 'PROCESSING':
        return {
          ready: state.ready.filter((i) => i !== action.value),
          processings: [...state.processings, action.value],
          completes: state.completes,
          errors: state.errors,
        };
      case 'COMPLETE':
        return {
          ready: state.ready,
          processings: state.processings.filter((i) => i !== action.value),
          completes: [...state.completes, action.value],
          errors: state.errors,
        };
      case 'ERROR':
        return {
          ready: state.ready,
          processings: state.processings.filter((i) => i !== action.value),
          completes: state.completes,
          errors: [...state.errors, action.value],
        };
      case 'RESET':
        return init([]);
      default:
        return state;
    }
  };
  return useReducer(reducer, [], init);
};

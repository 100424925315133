export enum VendorTradeDivisionEnum {
  // 仕入先取引区分
  CREDIT_PURCHASE = '1',
  CASH_PURCHASE = '2',
  FINISHED_PRODUCT = '3',
  WITHIN_COMPANY = '4',
  OTHER = '5',
  IMPORT_CASH = '6',
  IMPORT_CREDIT = '7',
  IMPORT_OTHER = '8',
}

export const VendorTradeDivision = [
  { label: '掛仕入', value: VendorTradeDivisionEnum.CREDIT_PURCHASE },
  { label: '現金仕入', value: VendorTradeDivisionEnum.CASH_PURCHASE },
  { label: '完成品', value: VendorTradeDivisionEnum.FINISHED_PRODUCT },
  { label: '社内間', value: VendorTradeDivisionEnum.WITHIN_COMPANY },
  { label: 'その他', value: VendorTradeDivisionEnum.OTHER },
  { label: '輸入(現金)', value: VendorTradeDivisionEnum.IMPORT_CASH },
  { label: '輸入(掛仕入)', value: VendorTradeDivisionEnum.IMPORT_CREDIT },
  { label: '輸入(その他)', value: VendorTradeDivisionEnum.IMPORT_OTHER },
];

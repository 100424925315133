export const FinishedProduct = [
  {
    label: '通常',
    value: '1',
  },
  {
    label: '構成部品',
    value: '2',
  },
  {
    label: '完成品',
    value: '3',
  },
];

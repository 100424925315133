import { To } from 'history';

import { MenuLink } from './MenuLink';
import { getBookmarkMenu } from './utils';

type BookmarkListProps = {
  bookmark: string[];
  removeBookmark: (to: To) => void;
};

export const BookmarkList = ({ bookmark, removeBookmark }: BookmarkListProps) => {
  const bookmarkMenu = getBookmarkMenu(bookmark);
  return (
    <ul
      id="sidemenu01"
      className="sidebar-dropdown list-unstyled collapse show"
      data-bs-parent="#sidebar"
    >
      {bookmarkMenu.map((link) => {
        return (
          <MenuLink
            to={link.to}
            end={link.end}
            key={link.title}
            onBookmark={(to) => {
              removeBookmark(to);
            }}
            active={true}
          >
            {link.title}
          </MenuLink>
        );
      })}
    </ul>
  );
};

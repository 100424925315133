export enum CustomerLedgerTaxTypeEnum {
  // 税種別
  INCLUDE_TAX = '1',
  EXCLUDE_TAX = '2',
  COMSUMPTION_TAX = '3',
}

export const CustomerLedgerTaxType = [
  { label: '税込', value: CustomerLedgerTaxTypeEnum.INCLUDE_TAX },
  { label: '税抜', value: CustomerLedgerTaxTypeEnum.EXCLUDE_TAX },
  { label: '消費税', value: CustomerLedgerTaxTypeEnum.COMSUMPTION_TAX },
];

import { CognitoUser } from 'amazon-cognito-identity-js';

import { UserResponse } from '../types';

import { getLoginUser, registerUser } from './cognito';

export type RegisterCredentialsDTO = {
  cognitoUser: CognitoUser;
  userAttributes: any;
  newPassword: string;
};

export const registerWithPassword = (data: RegisterCredentialsDTO): Promise<UserResponse> => {
  return new Promise((resolve, reject) => {
    registerUser(data)
      .then(({ idToken, refreshToken }) => {
        getLoginUser()
          .then((user) => {
            resolve({
              idToken: idToken,
              refreshToken: refreshToken,
              user: user,
            });
          })
          .catch(reject);
      })
      .catch(reject);
  });
};

export enum LedgerTotalingMethodEnum {
  // 集計方法
  MONTHLY = '1',
  SLIP = '2',
  DETAIL = '3',
}

export const LedgerTotalingMethod = [
  { label: '月次計', value: LedgerTotalingMethodEnum.MONTHLY },
  { label: '伝票計', value: LedgerTotalingMethodEnum.SLIP },
  { label: '明細計', value: LedgerTotalingMethodEnum.DETAIL },
];

import { useState } from 'react';
import { Modal } from 'react-bootstrap';

import { Button } from '@/components/Elements';

import { saveDefaultPrinter } from './defaultPrinter';
import { PrinterList } from './PrinterList';

type PrintProps = {
  handleShow: () => void;
  handleClose: () => void;
  handlePrint: (printerId: string, trayId: string, copies: number) => void;
};

export const Print = ({ handleShow, handleClose, handlePrint }: PrintProps) => {
  const [show, setShow] = useState(false);

  const [printerId, setPrinterId] = useState<string>();
  const [trayId, setTrayId] = useState<string>();
  const [copies, setCopies] = useState<number>(1);

  return (
    <>
      <Button
        type="button"
        variant="primary"
        onClick={() => {
          handleShow();
          setShow(true);
        }}
      >
        印刷
      </Button>
      <Modal
        size="lg"
        show={show}
        onHide={() => {
          setShow(false);
          handleClose();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>プリンター選択</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <PrinterList
              onChange={(printerId, trayId) => {
                setPrinterId(printerId);
                setTrayId(trayId);
              }}
            />
          </div>
          <div className="mt-4">
            <h5>印刷部数</h5>
            <input
              type="number"
              className="form-control h-12"
              onChange={(evt) => {
                setCopies(evt.currentTarget.valueAsNumber);
              }}
              defaultValue={1}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="button"
            variant="primary"
            onClick={() => {
              if (printerId) {
                handlePrint(printerId, trayId ?? '', copies);
                saveDefaultPrinter(printerId, trayId ?? '');
              }
              setShow(false);
            }}
          >
            印刷
          </Button>
          <Button
            type="button"
            variant="secondary"
            onClick={() => {
              setShow(false);
            }}
          >
            キャンセル
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

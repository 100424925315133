export const RoundTypeEnum = {
  /** 切り捨て */ FLOOR: '1',
  /** 切り上げ */ CEIL: '2',
  /** 四捨五入 */ ROUND: '3',
};
export const RoundType = [
  { label: '切り捨て', value: RoundTypeEnum.FLOOR },
  { label: '切り上げ', value: RoundTypeEnum.CEIL },
  { label: '四捨五入', value: RoundTypeEnum.ROUND },
];

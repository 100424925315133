import { DataMap } from '@grapecity/wijmo.grid';
import { useEffect, useState } from 'react';

import { useGetTaxDivisionQuery } from '@/generated/graphql';
import { getDataMap } from '@/utils/getDataMap';

import { BusinessCode } from '../types';

export type TaxDivisionCodes = BusinessCode & {
  taxRatio: number;
};

export const useTaxDivision = (): [DataMap | undefined, TaxDivisionCodes[]] => {
  const [taxDivisionDataMap, setDataMap] = useState<DataMap>();
  const [taxDivisionCodes, setCodes] = useState<TaxDivisionCodes[]>([]);

  const { data, isSuccess } = useGetTaxDivisionQuery(undefined, { suspense: true });

  useEffect(() => {
    if (isSuccess && data) {
      const taxDivision = data.getTaxDivision?.edges.map((e) => {
        return {
          label: e?.node?.taxationDivisionName ?? '',
          value: e?.node?.taxationDivision ?? '',
          taxRatio: e?.node?.taxRatio ?? 0,
        };
      });
      if (taxDivision) {
        setCodes(taxDivision);
        setDataMap(getDataMap(taxDivision));
      }
    }
  }, [data, isSuccess]);

  return [taxDivisionDataMap, taxDivisionCodes];
};

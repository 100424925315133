import { Japanese } from 'flatpickr/dist/l10n/ja';
import 'flatpickr/dist/themes/airbnb.css';
import Flatpickr, { DateTimePickerProps } from 'react-flatpickr';
import { Control, Controller } from 'react-hook-form';

import { toStringYyyyMmDdHyphen } from '@/utils/dateUtils';

import { FieldWrapper } from './FieldWrapper';

type DatePickerProps = DateTimePickerProps & {
  control: Control<any> | undefined;
  name: string;
  disabled?: boolean;
};

const CustomInput = ({
  defaultValue,
  className,
  inputRef,
}: Omit<DateTimePickerProps, 'render' | 'options'> & {
  inputRef: (node: HTMLInputElement) => void;
}) => {
  return (
    <input defaultValue={defaultValue} className={className} ref={inputRef} autoComplete="off" />
  );
};

export const DatePicker = (props: DatePickerProps) => {
  return (
    <Controller
      control={props.control}
      name={props.name}
      render={({ field: { onChange, value, name }, fieldState: { error } }) => (
        <>
          <FieldWrapper error={error}>
            <div className={props.readOnly ? 'form-readonly-overlay' : ''}>
              <Flatpickr
                className="fk__datepicker form-control"
                disabled={props.disabled}
                options={{
                  // YYYY/M/D
                  dateFormat: 'Y/n/j',
                  locale: Japanese,
                  allowInput: true,
                }}
                name={name}
                value={new Date(value)}
                onChange={(selectedDates) => {
                  if (selectedDates?.length > 0) {
                    const d = selectedDates[0];
                    onChange(toStringYyyyMmDdHyphen(d));
                  } else {
                    onChange('');
                  }
                }}
                render={({ defaultValue, className }, ref) => {
                  return (
                    <CustomInput defaultValue={defaultValue} className={className} inputRef={ref} />
                  );
                }}
              />
            </div>
          </FieldWrapper>
        </>
      )}
    />
  );
};

export enum AutomaticallyGeneratedFlgEnum {
  // 自動出金フラグ
  NOT_AUTO = '0',
  AUTO = '1',
}

// export const AutomaticallyGeneratedFlg = [
//   { label: '非自動出金', value: AutomaticallyGeneratedFlgEnum.NOT_AUTO },
//   { label: '自動出金', value: AutomaticallyGeneratedFlgEnum.AUTO },
// ];

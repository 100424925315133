import { To } from 'history';
import { useCallback, useState } from 'react';

import { getBookmark, setBookmark, unsetBookmark } from '../lib/bookmark';

export const useBookmark = () => {
  const [bookmark, setBm] = useState(getBookmark());

  const addBookmark = useCallback((to: To) => {
    setBookmark(to);
    setBm(getBookmark());
  }, []);

  const removeBookmark = useCallback((to: To) => {
    unsetBookmark(to);
    setBm(getBookmark());
  }, []);

  return { bookmark, addBookmark, removeBookmark };
};

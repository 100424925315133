import { DataMap } from '@grapecity/wijmo.grid';
import { useEffect, useState } from 'react';

import { useGetDepositDivisionQuery } from '@/generated/graphql';
import { getDataMap } from '@/utils/getDataMap';

import { BusinessCode, BusinessHooksReturn } from '../types';

type DepositDivisionCodes = BusinessCode & {
  depositGroupCode: string;
};

export const useDepositDivision = (): BusinessHooksReturn => {
  const [depositDivisionDataMap, setDataMap] = useState<DataMap>();
  const [depositDivisionCodes, setCodes] = useState<DepositDivisionCodes[]>([]);

  const { data, isSuccess } = useGetDepositDivisionQuery(undefined, { suspense: true });

  useEffect(() => {
    if (isSuccess && data) {
      const depositDivision = data.searchDepositDivision?.edges.map((e) => {
        return {
          label: e?.node?.depositDivisionName ?? '',
          value: e?.node?.depositDivisionCode ?? '',
          depositGroupCode: e?.node?.depositGroupCode ?? '',
        };
      });
      if (depositDivision) {
        setCodes(depositDivision);
        setDataMap(getDataMap(depositDivision));
      }
    }
  }, [data, isSuccess]);

  return [depositDivisionDataMap, depositDivisionCodes];
};

import { zodResolver } from '@hookform/resolvers/zod';
// import _ from 'lodash';
import _ from 'lodash';
import * as React from 'react';
import {
  useForm,
  UseFormReturn,
  UseFormProps,
  FieldError,
  UnpackNestedValue,
} from 'react-hook-form';
import { toast } from 'react-toastify';
import { ZodType, ZodTypeDef } from 'zod';

import { APIError, APIErrorMessage } from '@/fetcher/errors';
import { ALREADY_UPDATED, PRIMARY_KEY_EXISTS, RECORD_LOCKED } from '@/lib/message';

import { useFormGuard } from './hooks/useFormGuard';

type FormProps<TFormValues, Schema> = {
  className?: string;
  onSubmit: (
    data: UnpackNestedValue<TFormValues>,
    event?: React.BaseSyntheticEvent,
    methods?: UseFormReturn<TFormValues>
  ) => any | Promise<any>;
  children: (methods: UseFormReturn<TFormValues>) => React.ReactNode;
  options?: UseFormProps<TFormValues>;
  id?: string;
  schema?: Schema;
  guard?: boolean;
  guardIgnores?: (keyof TFormValues)[];
  skipGuardSubmitted?: boolean;
};

export const Form = <
  TFormValues extends Record<string, unknown> = Record<string, unknown>,
  Schema extends ZodType<unknown, ZodTypeDef, unknown> = ZodType<unknown, ZodTypeDef, unknown>
>({
  onSubmit,
  children,
  className,
  options,
  id,
  schema,
  guard,
  guardIgnores = [],
  skipGuardSubmitted,
}: FormProps<TFormValues, Schema>) => {
  const methods = useForm<TFormValues>({
    ...options,
    reValidateMode: 'onSubmit',
    resolver: schema && zodResolver(schema),
  });

  methods.formState.isDirty &&
    !_.isEmpty(methods.formState.dirtyFields) &&
    console.log(methods.formState.dirtyFields);

  if (methods.formState.errors) console.log(methods.formState.errors);

  useFormGuard(
    methods.formState.isDirty && !_.isEmpty(_.omit(methods.formState.dirtyFields, guardIgnores)),
    guard ?? false,
    skipGuardSubmitted,
    methods.formState.isSubmitSuccessful
  );
  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <form
      className={className}
      onKeyPress={(evt) => {
        if (evt.key === 'Enter' && _.get(evt.target, 'tagName') !== 'TEXTAREA') {
          evt.preventDefault();
        }
      }}
      onSubmit={(evt) => {
        evt.stopPropagation();
        methods
          .handleSubmit((data, evt) => onSubmit(data, evt, methods))(evt)
          .catch((err) => {
            if (err instanceof SubmitExecuteError) {
              err.errors.forEach((e) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                methods.setError(e.name, e.error, e.options);
              });
            } else if (err instanceof APIError) {
              let resolved = 0;
              err.errors.forEach((e) => {
                if (e.message === APIErrorMessage.PRIMARY_KEY_EXISTS) {
                  resolved++;
                  toast.error(PRIMARY_KEY_EXISTS);
                } else if (e.message === APIErrorMessage.VERSION_ERROR) {
                  resolved++;
                  toast.error(ALREADY_UPDATED);
                } else if (e.message === APIErrorMessage.RECORD_LOCK_ERROR) {
                  resolved++;
                  toast.error(RECORD_LOCKED);
                }
              });
              if (resolved === 0) throw err;
            } else {
              throw err;
            }
          });
      }}
      id={id}
      noValidate
    >
      {children(methods)}
    </form>
  );
};

export type FormError = {
  name: string;
  error: FieldError;
  options?: { shouldFocus: boolean };
};

export class SubmitExecuteError extends Error {
  errors: FormError[];
  constructor(errors: FormError[]) {
    super('SubmitExecuteError');
    this.errors = errors;
  }
}

export enum VendorTaxTypeEnum {
  // 仕入先税転嫁
  EXCLUDING_TAX_SLIP = '01',
  EXCLUDING_TAX_PURCHASE = '02',
  EXCLUDING_TAX_PURCHASE_ADJUST = '03',
  INCLUDED_TAX = '04',
  INCLUDED_TAX_TOTAL = '05',
  INCLUDED_TAX_PURCHASE = '06',
  EXPORT_TAX_FREE = '07',
  EXCLUDING_TAX_INPUT = '08',
  IMPORT = '09',
}

export const VendorTaxType = [
  { label: '外税/伝票計', value: VendorTaxTypeEnum.EXCLUDING_TAX_SLIP },
  { label: '外税/払締時', value: VendorTaxTypeEnum.EXCLUDING_TAX_PURCHASE },
  { label: '外税/払締時調整', value: VendorTaxTypeEnum.EXCLUDING_TAX_PURCHASE_ADJUST },
  { label: '内税', value: VendorTaxTypeEnum.INCLUDED_TAX },
  { label: '内税/総額', value: VendorTaxTypeEnum.INCLUDED_TAX_TOTAL },
  { label: '内税/払締時', value: VendorTaxTypeEnum.INCLUDED_TAX_PURCHASE },
  { label: '免税', value: VendorTaxTypeEnum.EXPORT_TAX_FREE },
  { label: '外税/手入力', value: VendorTaxTypeEnum.EXCLUDING_TAX_INPUT },
  { label: '輸入', value: VendorTaxTypeEnum.IMPORT },
];

export enum WarehouseTypeEnum {
  // 倉庫種別コード
  DIVISION = '1',
  EXTERNAL = '2',
  OUTSOURCE = '3',
}

export const WarehouseType = [
  { label: '部門倉庫', value: WarehouseTypeEnum.DIVISION },
  { label: '外部倉庫', value: WarehouseTypeEnum.EXTERNAL },
  { label: '委託先倉庫', value: WarehouseTypeEnum.OUTSOURCE },
];

import dayjs from 'dayjs';
import { useState } from 'react';
import { Modal, NavDropdown } from 'react-bootstrap';

export type NotificationProps = {
  key: string;
  date: string | null | undefined;
  title: string | null | undefined;
  message: string | null | undefined;
};

export const Notification = ({ key, title, message, date }: NotificationProps) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <NavDropdown.Item key={key} eventKey={key} className="list-group-item" onClick={handleShow}>
        <div>
          <small className="text-muted">{dayjs(date).format('YYYY/MM/DD hh:mm')}</small>
          <div>{title}</div>
        </div>
      </NavDropdown.Item>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="fk__heading__contents modal-title h4">{title}</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mx-8 text-right">
            <small className="text-muted">{dayjs(date).format('YYYY/MM/DD hh:mm')}</small>
          </div>
          <div className="mx-8 mb-4">
            <div className="text-xl" dangerouslySetInnerHTML={{ __html: message ?? '' }} />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

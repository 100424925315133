import { FC } from 'react';
import { Nav, NavDropdown } from 'react-bootstrap';

import { STAGE } from '@/config';
import { useAuth } from '@/lib/auth';

import { Notifications } from '../Notifications';

import { EnvMessage } from './EnvMessage';

interface Props {
  control?: React.ReactNode;
  title?: string;
  isCollapse: boolean;
  toggleCollapse: (value: boolean) => void;
}

export const Header: FC<Props> = (props: Props) => {
  const { user, logout } = useAuth();

  const sidebarToggle = () => {
    props.toggleCollapse(!props.isCollapse);
    // window.dispatchEvent(new Event('resize'));
  };

  const color = (() => {
    if (STAGE === 'DEMO' || STAGE === 'staging') {
      return '#00b8d4';
    } else if (STAGE === 'development') {
      return '#ffff00';
    } else {
      return '#fff';
    }
  })();

  const envMessage = (() => {
    if (STAGE === 'DEMO' || STAGE === 'staging') {
      return '検証環境';
    } else if (STAGE === 'development') {
      return '開発環境';
    } else {
      return undefined;
    }
  })();

  return (
    <div className="navbar-container">
      {envMessage && <EnvMessage envMessage={envMessage} color={color} />}
      <nav
        className={
          props.isCollapse
            ? 'navbar navbar-expand navbar-bg collapsed'
            : 'navbar navbar-expand navbar-bg'
        }
        style={{ backgroundColor: color }}
      >
        <button className="sidebar-toggle" onClick={sidebarToggle} tabIndex={-1}>
          <i className="hamburger align-self-center" />
        </button>
        <div className="fk__page-title">
          <h1>{props.title}</h1>
        </div>
        <Nav className="navbar-collapse justify-content-end">
          <Notifications />
          <NavDropdown
            title={
              <span className="text-dark">
                {user?.divisionName}&nbsp;{user?.userName}
              </span>
            }
          >
            {/* <NavDropdown.Item eventKey="1">プロフィール</NavDropdown.Item>
            <NavDropdown.Item eventKey="2">設定</NavDropdown.Item>
            <NavDropdown.Item eventKey="3">ヘルプ</NavDropdown.Item> */}
            <NavDropdown.Item
              eventKey="4"
              onClick={() => {
                logout();
              }}
            >
              ログアウト
            </NavDropdown.Item>
          </NavDropdown>
          <Nav.Item>
            <a href="/" tabIndex={-1}>
              <img
                className="fk__h-logo"
                src="/assets/img/folkart.svg"
                alt="株式会社フォーカート"
              />
            </a>
          </Nav.Item>
        </Nav>
      </nav>
      {props.control && (
        <div className={props.isCollapse ? 'navbar__sub collapsed' : 'navbar__sub'}>
          <div className="navbar__sub-container">{props.control}</div>
        </div>
      )}
    </div>
  );
};

import clsx from 'clsx';
import { useMemo } from 'react';
import { Accept, useDropzone } from 'react-dropzone';

const baseStyle: React.CSSProperties = {
  flex: 1,
  display: 'flex',
  minHeight: '120px',
  margin: '10px',
  flexDirection: 'column',
  alignItems: 'center',
  paddingTop: '34px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const activeStyle: React.CSSProperties = {
  borderColor: '#2196f3',
};
type DropzoneProps = {
  onDrop: (files: File[]) => void;
  accept?: Accept | undefined;
};

export const MultipleDropzone = ({ onDrop, accept }: DropzoneProps) => {
  const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone({
    onDrop,
    accept: accept,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
    }),
    [isDragActive]
  );

  return (
    <>
      {acceptedFiles.length > 0 ? (
        <div className={clsx('m-2.5')}>
          <div className={clsx('h-[20px]')}>{acceptedFiles.length}ファイルが選択されています</div>
          <div className={clsx('h-[100px]', 'overflow-y-scroll')}>
            {acceptedFiles.map((file) => (
              <div key={file.name}>・{file.name}</div>
            ))}
          </div>
        </div>
      ) : (
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <i className="fas fa-cloud-upload-alt fa-2x" />
          <p>ファイルまたはフォルダをドラッグしてください</p>
        </div>
      )}
    </>
  );
};

import dayjs from 'dayjs';
import ExcelJS from 'exceljs';

import { Button } from '../Button';

import { parseFormat } from './lib';

type Settings = {
  addDataValidation?: boolean;
};

export type ExportMapping = {
  header: string;
  binding: string;
  format?: string;
  width?: number;
  dataMap?: { label: string; value: any }[];
  dataType?: string;
  [key: string]: any;
};

type ExportDataOptions = {
  preDownloadHandler?: (workbook: ExcelJS.Workbook) => void;
};

export type ExportDataProps = Settings & {
  mapping: ExportMapping[];
  itemsSource?: any[];
  lazyProcessing?: (_i: any[]) => any[];
  loadData?: () => any[];
  fileName?: string;
  options?: ExportDataOptions;
  disabled?: boolean;
};

const convertData = (value: any, dataMap?: { label: string; value: any }[]): any => {
  if (dataMap) {
    const disp = dataMap.filter((map) => {
      return map.value === value;
    });
    return disp[0]?.label;
  } else {
    return value;
  }
};

export const generateExcel = (
  mapping: ExportMapping[],
  itemsSource: any[],
  fileName: string,
  settings: Settings,
  options: ExportDataOptions | undefined,
  onSuccess?: () => void
) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('sheet');
  const masterSheet = workbook.addWorksheet('master', { state: 'hidden' });

  // フォーマット定義
  worksheet.columns = mapping.map((conf) => {
    const colFmt: Partial<ExcelJS.Column> = {};
    colFmt.header = conf.header;
    colFmt.key = conf.binding;
    const colStyle: Partial<ExcelJS.Style> = {};
    if (conf.format) {
      const [fmt, result] = parseFormat(conf.format);
      if (result) colStyle.numFmt = fmt;
    }
    colFmt.style = colStyle;
    colFmt.width = conf.width;
    return colFmt;
  });

  // マスターシート定義
  const dataMaps = mapping.filter((map) => map.dataMap);
  dataMaps.forEach((conf, colNum) => {
    if (conf.dataMap) {
      conf.dataMap.forEach((data, rowNum) => {
        masterSheet.getRow(rowNum + 1).getCell(colNum + 1).value = data.label;
        masterSheet.getRow(rowNum + 1).getCell(colNum + 1).name = conf.binding;
      });
    }
  });

  // データの出力
  itemsSource.forEach((item) => {
    const row: { [key: string]: any } = {};
    mapping.forEach((col) => {
      row[col.binding] = convertData(item[col.binding], col.dataMap);
    });
    worksheet.addRow(row);
  });

  // データの入力規則の追加
  if (settings.addDataValidation) {
    dataMaps.forEach((conf) => {
      if (conf.dataMap) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        worksheet.getColumn(conf.binding).eachCell((cell, num) => {
          if (num === 1) return;
          cell.dataValidation = {
            type: 'list',
            formulae: [conf.binding],
            // formulae: ['"One,Two,Three,Four"'],
          };
        });
      }
    });
  }

  // 画像データの埋め込み
  mapping.forEach((conf) => {
    if (conf.dataType == 'Base64Image') {
      worksheet.getColumn(conf.binding).width = 13;
      worksheet.getColumn(conf.binding).eachCell((cell, num) => {
        if (num === 1) return;
        if (cell.value) {
          const img = workbook.addImage({
            base64: cell.value as string,
            extension: 'jpeg',
          });
          worksheet.addImage(img, {
            tl: { col: parseInt(cell.col) - 1, row: parseInt(cell.row) - 1 },
            ext: { width: 100, height: 100 },
          });
          cell.value = '';
          worksheet.getRow(parseInt(cell.row)).height = 75;
        }
      });
    }
  });

  if (options?.preDownloadHandler) options.preDownloadHandler(workbook);

  // Excelデータのダウンロード
  workbook.xlsx.writeBuffer().then((uint8Array) => {
    const blob = new Blob([uint8Array], { type: 'application/octet-binary' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
    if (onSuccess) onSuccess();
  });
};

export const generateFileName = (fileName: string): string => {
  const today = dayjs().format('YYYYMMDD');
  const _fileName = fileName ? `${today}_${fileName}.xlsx` : `${today}_export.xlsx`;
  return _fileName;
};

export const ExportData = ({
  mapping,
  itemsSource,
  loadData,
  fileName = 'export',
  addDataValidation,
  options,
  disabled,
}: ExportDataProps) => {
  return (
    <>
      <Button
        disabled={disabled}
        variant="secondary"
        onClick={() => {
          if (itemsSource) {
            generateExcel(
              mapping,
              itemsSource,
              generateFileName(fileName),
              { addDataValidation },
              options
            );
          } else {
            if (loadData) {
              const data = loadData() ?? [];
              generateExcel(
                mapping,
                data,
                generateFileName(fileName),
                { addDataValidation },
                options
              );
            }
          }
        }}
      >
        エクスポート
      </Button>
    </>
  );
};

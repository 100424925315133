import clsx from 'clsx';
import _ from 'lodash';
import { UseFormRegisterReturn } from 'react-hook-form';

import { FieldWrapper, FieldWrapperPassThroughProps } from './FieldWrapper';

type TextAreaFieldProps = FieldWrapperPassThroughProps & {
  className?: string;
  registration: Partial<UseFormRegisterReturn>;
  maxLength?: number;
};

export const TextAreaField = (props: TextAreaFieldProps) => {
  const { label, className, registration, error, maxLength } = props;
  return (
    <FieldWrapper label={label} error={error}>
      <textarea
        className={clsx('form-control', className)}
        name={registration.name}
        ref={(e) => {
          registration?.ref && registration.ref(e);
          if (e) {
            const value = e.value;
            const rows = _.countBy(value)['\n'] + 1;
            e.rows = rows > 10 ? 10 : rows;
          }
        }}
        onChange={(e) => {
          registration?.onChange && registration.onChange(e);
          const value = e.currentTarget.value;
          const rows = _.countBy(value)['\n'] + 1;
          e.currentTarget.rows = rows > 10 ? 10 : rows;
        }}
        onBlur={registration.onBlur}
        maxLength={maxLength}
      />
    </FieldWrapper>
  );
};

export enum TransferShareCostEnum {
  // 振込料負担先コード
  OUR_SIDE = '1',
  OTHER_SIDE = '2',
}

export const TransferShareCost = [
  { label: '当方', value: TransferShareCostEnum.OUR_SIDE },
  { label: '先方', value: TransferShareCostEnum.OTHER_SIDE },
];

export const compareValue = (
  val1: string | number | any[] | undefined,
  val2: string | number
): boolean => {
  if (val1 === undefined) return false;
  if (Array.isArray(val1)) {
    return val1.includes(val2);
  } else {
    return val1 === val2;
  }
};

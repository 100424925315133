// import _ from 'lodash';
import { zodResolver } from '@hookform/resolvers/zod';
import _ from 'lodash';
import * as React from 'react';
import {
  UseFormReturn,
  SubmitHandler,
  FieldError,
  useForm,
  UseFormProps,
  FormProvider,
} from 'react-hook-form';
import { toast } from 'react-toastify';
import { ZodType, ZodTypeDef } from 'zod';

import { APIError, APIErrorMessage } from '@/fetcher/errors';
import { ALREADY_UPDATED, PRIMARY_KEY_EXISTS, RECORD_LOCKED } from '@/lib/message';

// import _ from 'lodash';

type DialogFormContextProps<TFormValues, Schema> = {
  className?: string;
  children: (methods: UseFormReturn<TFormValues>) => React.ReactNode;
  options?: UseFormProps<TFormValues>;
  schema?: Schema;
};

export const DialogFormContext = <
  TFormValues extends Record<string, unknown> = Record<string, unknown>,
  Schema extends ZodType<unknown, ZodTypeDef, unknown> = ZodType<unknown, ZodTypeDef, unknown>
>({
  children,
  options,
  schema,
}: DialogFormContextProps<TFormValues, Schema>) => {
  const methods = useForm<TFormValues>({
    ...options,
    reValidateMode: 'onSubmit',
    resolver: schema && zodResolver(schema),
  });
  return <FormProvider {...methods}>{children(methods)}</FormProvider>;
};

type DialogFormProps<TFormValues> = {
  className?: string;
  onSubmit: SubmitHandler<TFormValues>;
  children: (methods: UseFormReturn<TFormValues>) => React.ReactNode;
  id?: string;
  methods: UseFormReturn<TFormValues>;
};

export const DialogForm = <TFormValues extends Record<string, unknown> = Record<string, unknown>>({
  onSubmit,
  children,
  className,
  id,
  methods,
}: DialogFormProps<TFormValues>) => {
  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <form
      className={className}
      onKeyPress={(evt) => {
        if (evt.key === 'Enter' && _.get(evt.target, 'tagName') !== 'TEXTAREA') {
          evt.preventDefault();
        }
      }}
      onSubmit={(evt) => {
        evt.stopPropagation();
        methods
          .handleSubmit(onSubmit)(evt)
          .catch((err) => {
            if (err instanceof SubmitExecuteError) {
              err.errors.forEach((e) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                methods.setError(e.name, e.error, e.options);
              });
            } else if (err instanceof APIError) {
              let resolved = 0;
              err.errors.forEach((e) => {
                if (e.message === APIErrorMessage.PRIMARY_KEY_EXISTS) {
                  resolved++;
                  toast.error(PRIMARY_KEY_EXISTS);
                } else if (e.message === APIErrorMessage.VERSION_ERROR) {
                  resolved++;
                  toast.error(ALREADY_UPDATED);
                } else if (e.message === APIErrorMessage.RECORD_LOCK_ERROR) {
                  resolved++;
                  toast.error(RECORD_LOCKED);
                }
              });
              if (resolved === 0) throw err;
            } else {
              throw err;
            }
          });
      }}
      id={id}
      noValidate
    >
      {children(methods)}
    </form>
  );
};

type FormError = {
  name: string;
  error: FieldError;
  options?: { shouldFocus: boolean };
};

class SubmitExecuteError extends Error {
  errors: FormError[];
  constructor(errors: FormError[]) {
    super('SubmitExecuteError');
    this.errors = errors;
  }
}

export enum SalesTradeDivisionEnum {
  // 得意先取引区分
  /** 掛売上 */ CREDIT_SALES = '1',
  /** 現金売上 */ CASH_SALES = '2',
  /** 代引 */ CASH_ON_DELIVERY = '3',
  /** 都度請求 */ EACH_DEMAND = '4',
  /** 社内間 */ WITHIN_COMPANY = '5',
}

export const SalesTradeDivision = [
  { label: '掛売上', value: SalesTradeDivisionEnum.CREDIT_SALES },
  { label: '現金売上', value: SalesTradeDivisionEnum.CASH_SALES },
  { label: '代引', value: SalesTradeDivisionEnum.CASH_ON_DELIVERY },
  { label: '都度請求', value: SalesTradeDivisionEnum.EACH_DEMAND },
  { label: '社内間', value: SalesTradeDivisionEnum.WITHIN_COMPANY },
];

export const DemandTradeDivision = [
  { label: '締請求', value: SalesTradeDivisionEnum.CREDIT_SALES },
  { label: '現金請求', value: SalesTradeDivisionEnum.CASH_SALES },
  { label: '代引き請求', value: SalesTradeDivisionEnum.CASH_ON_DELIVERY },
  { label: '都度請求', value: SalesTradeDivisionEnum.EACH_DEMAND },
];

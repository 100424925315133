/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import * as React from 'react';
import { Dropdown } from 'react-bootstrap';

import { Spinner } from '@/components/Elements/Spinner';

const variants = {
  primary: 'btn-outline-primary',
  secondary: 'btn-outline-secondary',
  inverse: 'bg-white text-blue-600 hover:bg-blue-600:text-white',
  danger: 'bg-red-600 text-white hover:bg-red-50:text-red-600',
};

const sizes = {
  sm: 'py-2 px-4 text-sm',
  md: 'py-2 px-6 text-md',
  lg: 'py-3 px-8 text-lg',
};

type IconProps =
  | { startIcon: React.ReactElement; endIcon?: never }
  | { endIcon: React.ReactElement; startIcon?: never }
  | { endIcon?: undefined; startIcon?: undefined };

type DeleteButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  isLoading?: boolean;
};

type CustomToggleProps = {
  onClick: (e: any) => void;
};

const CustomToggle = React.forwardRef<HTMLAnchorElement, CustomToggleProps>(
  ({ children, onClick }, ref) => (
    <a
      className="text-black no-underline"
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </a>
  )
);
CustomToggle.displayName = 'CustomToggle';

export const DeleteButton = React.forwardRef<HTMLButtonElement, DeleteButtonProps>(
  ({ onClick, disabled }, ref) => {
    return (
      <Dropdown>
        <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
          …
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item eventKey="1" onClick={onClick} disabled={disabled}>
            削除
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
);

DeleteButton.displayName = 'DeleteButton';

export const CountryOfOrigin = [
  {
    label: '中国',
    value: 'CHN',
  },
  {
    label: '台湾',
    value: 'TWN',
  },
  {
    label: 'アメリカ',
    value: 'USA',
  },
  {
    label: 'ベトナム',
    value: 'VNM',
  },
];

export enum SendOrderTypeEnum {
  /** 通常 */ NORMAL = '1',
  /** 返品 */ RETURN = '2',
  /** 交換 */ CHANGED = '3',
}

export const SendOrderType = [
  { label: '通常', value: SendOrderTypeEnum.NORMAL },
  { label: '返品', value: SendOrderTypeEnum.RETURN },
  { label: '交換', value: SendOrderTypeEnum.CHANGED },
];

import { CognitoUser } from 'amazon-cognito-identity-js';

export class NewPasswordRequired extends Error {
  constructor(public cognitoUser: CognitoUser, public userAttributes: any, e?: string) {
    super(e);
    this.name = new.target.name;

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor);
    }

    Object.setPrototypeOf(this, new.target.prototype);
  }
}

export const NODE_ENV = process.env.NODE_ENV as string;
export const APP_ENV = process.env.REACT_APP_APP_ENV as string;
export const API_MOCKING = process.env.REACT_APP_API_MOCKING as string;
export const API_URL = process.env.REACT_APP_API_URL as string;
export const JWT_SECRET = '123456' as string;
export const WIJMO_KEY = process.env.REACT_APP_WIJMO_KEY as string;
export const AWS_REGION = process.env.REACT_APP_AWS_REGION as string;
export const COGNITO_USERPOOL_ID = process.env.REACT_APP_COGNITO_USERPOOL_ID as string;
export const COGNITO_CLIENT_ID = process.env.REACT_APP_COGNITO_CLIENT_ID as string;
export const BACKEND_URI = process.env.REACT_APP_BACKEND_URI as string;
export const LOGROCKET_CODE = process.env.REACT_APP_LOGROCKET_CODE as string;
export const SENTRY_CODE = process.env.REACT_APP_SENTRY_CODE as string;
export const IMAGE_URI = process.env.REACT_APP_IMAGE_URI as string;
export const IMAGE_UPLOAD_URI = process.env.REACT_APP_IMAGE_UPLOAD_URI as string;
export const STAGE = process.env.REACT_APP_STAGE as string;

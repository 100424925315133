import { ZodIssueOptionalMessage, ZodIssueCode } from 'zod';
// import { util } from 'zod/lib/helpers/util';

import {
  INVALID_DATE,
  INVALID_INPUT,
  REQUIRED,
  formatMessage,
  UNRECOGNIED_KEYS,
  INVAILD_FUNC_ARGS,
  INVAILD_FUNC_RET_TYPE,
  INVAILD_INTERSECTION_TYPE,
  NUMBER_INPUT,
  STRING_INPUT,
} from './message';

type ErrorMapCtx = {
  // path: (string | number)[];
  // details: any;
  defaultError: string;
  data: any;
  // metadata: object;
};

export const customErrorMap = (
  error: ZodIssueOptionalMessage,
  _ctx: ErrorMapCtx
): { message: string } => {
  let message: string;
  switch (error.code) {
    case ZodIssueCode.invalid_type:
      if (error.received === 'undefined') {
        message = REQUIRED;
      } else if (error.received === 'null') {
        message = REQUIRED;
      } else {
        if (error.expected === 'string') {
          message = STRING_INPUT;
        } else if (error.expected === 'number') {
          message = NUMBER_INPUT;
        } else {
          message = INVALID_INPUT;
        }
      }
      break;
    case ZodIssueCode.unrecognized_keys:
      // message = `Unrecognized key(s) in object: ${error.keys.map((k) => `'${k}'`).join(', ')}`;
      message = formatMessage(UNRECOGNIED_KEYS, error.keys.map((k) => `'${k}'`).join(', '));
      break;
    case ZodIssueCode.invalid_union:
      message = INVALID_INPUT;
      break;
    case ZodIssueCode.invalid_enum_value:
      // message = `Invalid enum value. Expected ${error.options
      //   .map((val) => (typeof val === 'string' ? `'${val}'` : val))
      //   .join(' | ')}, received ${typeof _ctx.data === 'string' ? `'${_ctx.data}'` : _ctx.data}`;
      message = `${
        typeof _ctx.data === 'string' ? `「${_ctx.data}」` : _ctx.data
      } ではなく、${error.options
        .map((val) => (typeof val === 'string' ? `「${val}」` : val))
        .join(' または ')} を入力してください。`;
      break;
    case ZodIssueCode.invalid_arguments:
      message = INVAILD_FUNC_ARGS;
      break;
    case ZodIssueCode.invalid_return_type:
      message = INVAILD_FUNC_RET_TYPE;
      break;
    case ZodIssueCode.invalid_date:
      message = INVALID_DATE;
      break;
    // case ZodIssueCode.too_small:
    //   const tooShortNoun = _ctx.data === 'string' ? 'characters' : 'items';
    //   message = `Too short, should be at least ${error.minimum} ${tooShortNoun}`;
    //   break;
    // case ZodIssueCode.too_big:
    //   const tooLongNoun = _ctx.data === 'string' ? 'characters' : 'items';
    //   message = `Too short, should be at most ${error.maximum} ${tooLongNoun}`;
    //   break;
    case ZodIssueCode.invalid_string:
      if (error.validation !== 'regex') message = `Invalid ${error.validation}`;
      else message = INVALID_INPUT;
      break;
    // case ZodIssueCode.invalid_url:
    //   message = 'Invalid URL.';
    //   break;
    // case ZodIssueCode.invalid_uuid:
    //   message = 'Invalid UUID.';
    //   break;
    case ZodIssueCode.too_small:
      if (error.type === 'array')
        message =
          `要素数が${error.minimum}` +
          `${error.inclusive ? `以上の` : `より大きい`}配列を入力してください。`;
      else if (error.type === 'string')
        message =
          `${error.minimum}文字` + `${error.inclusive ? `以上で` : `より大きく`}入力してください。`;
      else if (error.type === 'number')
        message =
          `${error.minimum}` + `${error.inclusive ? `以上で` : `より大きく`}入力してください。`;
      else message = 'Invalid input';
      break;
    case ZodIssueCode.too_big:
      if (error.type === 'array')
        message =
          `要素数が${error.maximum}` +
          `${error.inclusive ? `以下の` : `より小さい`}配列を入力してください`;
      else if (error.type === 'string')
        message =
          `${error.maximum}文字` + `${error.inclusive ? `以下で` : `未満で`}入力してください。`;
      else if (error.type === 'number')
        message = `${error.maximum}` + `${error.inclusive ? `以下で` : `未満で`}入力してください。`;
      else message = INVALID_INPUT;
      break;
    case ZodIssueCode.custom:
      message = INVALID_INPUT;
      break;
    case ZodIssueCode.invalid_intersection_types:
      // message = `Intersections only support objects`;
      message = INVAILD_INTERSECTION_TYPE;
      break;
    default:
      message = _ctx.defaultError;
    // util.assertNever(error);
  }
  return { message };
  // return `Invalid input`;
};

export enum VendorClosingGroupCodeEnum {
  // 締グループ
  FIVE = '05',
  TEN = '10',
  FIFTEEN = '15',
  TWENTY = '20',
  TWENTY_FIVE = '25',
  THIRTY = '31',
  OTHER = '99',
}

export const VendorClosingGroupCode = [
  { label: '毎月5日締', value: VendorClosingGroupCodeEnum.FIVE },
  { label: '毎月10日締', value: VendorClosingGroupCodeEnum.TEN },
  { label: '毎月15日締', value: VendorClosingGroupCodeEnum.FIFTEEN },
  { label: '毎月20日締', value: VendorClosingGroupCodeEnum.TWENTY },
  { label: '毎月25日締', value: VendorClosingGroupCodeEnum.TWENTY_FIVE },
  { label: '毎月末日締', value: VendorClosingGroupCodeEnum.THIRTY },
  { label: 'その他', value: VendorClosingGroupCodeEnum.OTHER },
];

export enum ProductGroupTypeTypeEnum {
  // 商品分類別
  GROUP_TYPE_CODE1 = '1',
  GROUP_TYPE_CODE2 = '2',
  GROUP_TYPE_CODE3 = '3',
}

export const ProductGrouptype = [
  { label: '商品分類１', value: ProductGroupTypeTypeEnum.GROUP_TYPE_CODE1 },
  { label: '商品分類２', value: ProductGroupTypeTypeEnum.GROUP_TYPE_CODE2 },
  { label: '商品分類３', value: ProductGroupTypeTypeEnum.GROUP_TYPE_CODE3 },
];

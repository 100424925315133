import './wijmo.culture.ja';

import * as wjcCore from '@grapecity/wijmo';
import { z } from 'zod';

import { AppProvider } from '@/providers/app';
import { AppRoutes } from '@/routes';

// CSS
import 'flatpickr/dist/themes/airbnb.css';
import 'flatpickr/dist/plugins/monthSelect/style.css';
import '@grapecity/wijmo.styles/wijmo.css';
import '@fortawesome/fontawesome-free/css/all.css';
import './style/style.scss';
import { WIJMO_KEY } from './config';
import { customErrorMap } from './lib/zodError';

wjcCore.setLicenseKey(WIJMO_KEY);
z.setErrorMap(customErrorMap);

function App() {
  return (
    <AppProvider>
      <AppRoutes />
    </AppProvider>
  );
}

export default App;

export const hankakuZenkakuLen = (_str: string) => {
  let len = 0;
  const str = _str.split('');
  for (let i = 0; i < str.length; i++) {
    if (str[i].match(/[ｦ-ﾟ]+/)) {
      // 半角カタカナ
      len++;
    } else {
      const uint8Array = new TextEncoder().encode(str[i]);
      if (uint8Array.length !== 1) {
        // 全角
        len += 2;
      } else {
        // 半角英数
        len++;
      }
    }
  }

  return len;
};

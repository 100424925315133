import clsx from 'clsx';
import * as React from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

import { compareValue } from '@/utils/compareValue';

import { FieldWrapper, FieldWrapperPassThroughProps } from './FieldWrapper';

type Option = {
  label: React.ReactNode;
  value: string | number;
};

type CheckboxFieldProps = FieldWrapperPassThroughProps & {
  options: Option[];
  className?: string;
  defaultValue?: string | number | string[];
  registration: Partial<UseFormRegisterReturn>;
  disabled?: boolean;
  readOnly?: boolean;
};

export const CheckboxField = (props: CheckboxFieldProps) => {
  const { label, options, error, className, defaultValue, registration, disabled, readOnly } =
    props;
  return (
    <FieldWrapper label={label} error={error}>
      <div className="row row-cols-auto">
        {options.map(({ label, value }) => (
          <div className="col" key={value}>
            <div className={clsx('form-check fk__alt-form-control fk__alt-form-control__label')}>
              {readOnly ? (
                <>
                  <input
                    type="checkbox"
                    className={clsx('form-check-input', className)}
                    value={value}
                    defaultChecked={compareValue(defaultValue, value)}
                    ref={registration.ref}
                    id={`${registration.name}-${value}`}
                    disabled={readOnly}
                  />
                  <input
                    type="checkbox"
                    className={clsx('form-readonly-invisible')}
                    value={value}
                    defaultChecked={compareValue(defaultValue, value)}
                    {...registration}
                  />
                  <label className="form-check-label" htmlFor={`${registration.name}-${value}`}>
                    {label}
                  </label>
                </>
              ) : (
                <>
                  <input
                    type="checkbox"
                    className={clsx('form-check-input', className)}
                    value={value}
                    defaultChecked={compareValue(defaultValue, value)}
                    {...registration}
                    id={`${registration.name}-${value}`}
                    disabled={disabled || readOnly}
                  />
                  <label className="form-check-label" htmlFor={`${registration.name}-${value}`}>
                    {label}
                  </label>
                </>
              )}
            </div>
          </div>
        ))}
      </div>
    </FieldWrapper>
  );
};

import { Japanese } from 'flatpickr/dist/l10n/ja';
import 'flatpickr/dist/themes/airbnb.css';
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect';
import Flatpickr, { DateTimePickerProps } from 'react-flatpickr';
import { Control, Controller } from 'react-hook-form';

import { toStringYyyyMmDdHyphen } from '@/utils/dateUtils';

import { FieldWrapper } from './FieldWrapper';

type MonthPickerProps = DateTimePickerProps & {
  control: Control<any> | undefined;
  name: string;
  defaultToday?: boolean;
  disabled?: boolean;
};

export const MonthPicker = (props: MonthPickerProps) => {
  return (
    <Controller
      control={props.control}
      name={props.name}
      render={({ field: { onChange, value, name }, fieldState: { error } }) => (
        <>
          <FieldWrapper error={error}>
            <div className={props.readOnly ? 'form-readonly-overlay' : ''}>
              <Flatpickr
                className="fk__datepicker form-control readonly"
                disabled={props.disabled}
                options={{
                  // YYYY/M/D
                  dateFormat: 'Y/n/j',
                  locale: Japanese,
                  plugins: [
                    monthSelectPlugin({
                      shorthand: true,
                      dateFormat: 'Y/n',
                      theme: 'airbnb',
                    }),
                  ],
                }}
                name={name}
                value={new Date(value)}
                onChange={(selectedDates) => {
                  if (selectedDates?.length > 0) {
                    const d = selectedDates[0];
                    onChange(toStringYyyyMmDdHyphen(d));
                  } else onChange('');
                }}
                onReady={() => {
                  if (value) {
                    onChange(value);
                  } else if (props.defaultToday) {
                    const d = new Date();
                    onChange(toStringYyyyMmDdHyphen(d));
                  } else onChange('');
                }}
              />
            </div>
          </FieldWrapper>
        </>
      )}
    />
  );
};

export enum ComponentDivisionEnum {
  // 仕入先取引区分
  NOMAL_PRODUCT = '1',
  COMPONENT_PRODUCT = '2',
  FINISH_PRODUCT = '3',
}

export const ComponentDivision = [
  { label: '通常', value: ComponentDivisionEnum.NOMAL_PRODUCT },
  { label: '構成品', value: ComponentDivisionEnum.COMPONENT_PRODUCT },
  { label: '完成品', value: ComponentDivisionEnum.FINISH_PRODUCT },
];

import { useState } from 'react';

type BookMarkButtonProps = {
  active: boolean;
  onClick: (actived: boolean) => void;
};

const xor = (a: boolean, b: boolean): boolean => {
  return (a && !b) || (!a && b);
};

export const BookmarkButton = ({ onClick, active }: BookMarkButtonProps) => {
  const [hovered, setHovered] = useState(false);
  const toggleHover = () => setHovered(!hovered);
  return (
    <button
      tabIndex={-1}
      data-fav={xor(active, hovered) ? 'fav' : ''}
      className="js__fk__fav fk__fav-btn badge"
      onClick={() => {
        onClick(active);
      }}
      onMouseEnter={toggleHover}
      onMouseLeave={toggleHover}
    >
      <i className="far fa-star fa-lg" />
    </button>
  );
};

import { z } from 'zod';

import { hankakuZenkakuLen } from './hankakuZenkaku';
import {
  FIX_VALUE,
  MAX_VALUE,
  formatMessage,
  NOT_EMAIL,
  NOT_NUMBER_HYPHEN,
  NOT_YYYYMMDD_HYPHEN,
  REQUIRED,
  MAX_HANKAKU_ZENKAKU,
  NUMBER_RANGE,
  NUMBER_DECIMAL_DIGITS,
  NUMBER_INT,
  NOT_NUMBER,
  NOT_SELECTED_DATE,
  NOT_HANKAKU_HYPHEN,
  MIN_VALUE,
  MUST_EMPTY,
  NOT_HANKAKU,
  NOT_YYYYMMDD_SLASH,
  FIXED_ALPHA_NUM_HANKAKU,
  MAX_ALPHA_NUM_HANKAKU,
  PASSWORD_REGIX,
  NOT_PHONE_NUMBER,
} from './message';

export const zodRequired = () => {
  return z.string().min(1, formatMessage(REQUIRED));
};

export const zodCodeSelectRequired = () => {
  return z.object({
    label: zodRequired(),
    value: zodRequired(),
  });
};

export const zodEmpty = () => {
  return z.undefined().or(z.string().max(0, formatMessage(MUST_EMPTY)));
};

export const zodNum = () => {
  return z.string().transform((v) => {
    return v === '' ? null : Number(v);
  });
};

export const zodRequiredNum = () => {
  return z
    .string()
    .min(1, formatMessage(REQUIRED))
    .transform((v) => {
      return Number(v);
    });
};

export const zodNumValue = () => {
  return z.onumber();
};

export const zodRequiredNumValue = () => {
  return z.number();
};

export const zodFixLength = (num: number) => {
  return z.string().min(num, formatMessage(FIX_VALUE, num.toString())).or(z.string().max(0));
};

export const zodFixBetweenLength = (min_num: number, max_num: number) => {
  return z
    .string()
    .min(min_num, formatMessage(MIN_VALUE, min_num.toString()))
    .max(max_num, formatMessage(MAX_VALUE, max_num.toString()))
    .or(z.string().max(0));
};

export const zodEmail = () => {
  return z.string().email(formatMessage(NOT_EMAIL)).or(z.string().max(0));
};

export const zodPasswordPolicy = () => {
  return z.string().min(8, formatMessage(MIN_VALUE, '8')).superRefine(passwordPolicy);
};

export const zodUpdatePassword = () => {
  return z
    .union([z.string().min(8, formatMessage(MIN_VALUE, '8')), z.string().length(0)])
    .superRefine(passwordPolicy);
};

const passwordPolicy = (val: string, ctx: z.RefinementCtx) => {
  if (val === '') return;
  // 小文字を含む
  if (!/(?=.*[a-z])/.test(val)) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: formatMessage(PASSWORD_REGIX, '小文字'),
    });
  }
  // 大文字を含む
  if (!/(?=.*[A-Z])/.test(val)) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: formatMessage(PASSWORD_REGIX, '大文字'),
    });
  }
  // 数字を含む
  if (!/(?=.*[1-9])/.test(val)) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: formatMessage(PASSWORD_REGIX, '数字'),
    });
  }
  // 記号を含む
  if (!/(?=.*[\^$*.[\]{}()?\-"!@#%&/\\,><':;|_~`+=])/.test(val)) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: formatMessage(PASSWORD_REGIX, '記号'),
    });
  }
};

export const zodNumForm = () => {
  return z
    .string()
    .refine((v) => {
      return v.match(/^[0-9]+$/);
    }, NOT_NUMBER)
    .or(z.string().max(0));
};

export const zodNotNumHyphen = () => {
  return z
    .string()
    .refine((v) => {
      return v.match(/^[0-9+]+[0-9-]+[0-9]$/);
    }, NOT_NUMBER_HYPHEN)
    .or(z.string().max(0));
};

export const zodTel = () => {
  return z
    .string()
    .refine((v) => {
      return v.match(/^[0-9+][0-9-\s]+[0-9]$/);
    }, NOT_PHONE_NUMBER)
    .or(z.string().max(0));
};

export const zodhankaku = () => {
  return z
    .string()
    .refine((v) => {
      return v.match(/^[0-9a-zA-Z]+$/);
    }, NOT_HANKAKU)
    .or(z.string().max(0));
};

export const zodhankakuHyphen = () => {
  return z
    .string()
    .refine((v) => {
      return v.match(/^[0-9a-zA-Z\\-]+$/);
    }, NOT_HANKAKU_HYPHEN)
    .or(z.string().max(0));
};

export const zodCheckboxSingle = () => {
  return z
    .string()
    .nullable()
    .optional()
    .transform((v) => {
      if (!v) {
        return null;
      } else {
        return v;
      }
    });
};

export const zodSelect = () => {
  return z.object({
    label: z.string(),
    value: z.string(),
  });
};

export const zodSelectOptional = () => {
  return z
    .object({
      label: z.string(),
      value: z.string(),
    })
    .nullable()
    .optional();
};

/**
 * string 桁数固定
 * @param length - 桁数
 * @return z
 */
export const stringFixedLength = (length: number) => {
  return z
    .string()
    .min(length, formatMessage(FIX_VALUE, length.toString()))
    .max(length, formatMessage(FIX_VALUE, length.toString()));
};

/**
 * string 最大桁数指定
 * @param length - 桁数
 * @return z
 */
export const stringMaxLength = (length: number) => {
  return z.string().max(length, formatMessage(MAX_VALUE, length.toString()));
};

/**
 * date 日付 YYYY-MM-DD
 * @return z
 */
export const dateYyyyMmDdHyphen = (isDatePicker?: boolean) => {
  return z.string().refine(
    (v) => {
      return v.match(/^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/);
    },
    isDatePicker ? NOT_SELECTED_DATE : NOT_YYYYMMDD_HYPHEN
  );
};

/**
 * date 日付 YYYY/MM/DD
 * @return z
 */
export const dateYyyyMmDdSlash = (isDatePicker?: boolean) => {
  return z.string().refine(
    (v) => {
      return v.match(/^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/);
    },
    isDatePicker ? NOT_SELECTED_DATE : NOT_YYYYMMDD_SLASH
  );
};

/**
 * 半角全角文字数バリデーション
 * @param _length 半角文字数
 * @returns z
 */
export const hankakuZenkakuMax = (_length: number) => {
  return z.string().superRefine((val, ctx) => {
    const len = hankakuZenkakuLen(val);
    if (len > _length) {
      ctx.addIssue({
        code: z.ZodIssueCode.too_big,
        maximum: _length,
        type: 'string',
        inclusive: true,
        message: formatMessage(
          MAX_HANKAKU_ZENKAKU,
          String(_length),
          String(Math.floor(_length / 2))
        ),
      });
    }
  });
};

/**
 * 半角英数字数バリデーション
 * @param _required 必須
 * @param _length 半角指定文字数（英数字のみ）
 * @returns z
 */
export const hankakuAlphaNumFixed = (_required: boolean, _length: number) => {
  return z
    .string()
    .optional()
    .nullable()
    .superRefine((val, ctx) => {
      if (_required) {
        // 必須
        const _val = val ?? '';
        const len = hankakuZenkakuLen(_val);
        if (len !== _length) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: formatMessage(FIXED_ALPHA_NUM_HANKAKU, String(_length)),
          });
        } else {
          if (!_val.match(/^[0-9a-zA-Z]+$/)) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: formatMessage(FIXED_ALPHA_NUM_HANKAKU, String(_length)),
            });
          }
        }
      } else {
        // 任意
        if (val) {
          const len = hankakuZenkakuLen(val);
          if (len !== _length) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: formatMessage(FIXED_ALPHA_NUM_HANKAKU, String(_length)),
            });
          }
          if (!val.match(/^[0-9a-zA-Z]+$/)) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: formatMessage(FIXED_ALPHA_NUM_HANKAKU, String(_length)),
            });
          }
        }
      }
    });
};

/**
 * 半角英数字数バリデーション
 * @param _required 必須
 * @param _length 半角最大文字数（英数字のみ）
 * @returns z
 */
export const hankakuAlphaNumMax = (_required: boolean, _length: number) => {
  return z
    .string()
    .optional()
    .nullable()
    .superRefine((val, ctx) => {
      if (_required) {
        // 必須
        const _val = val ?? '';
        const len = hankakuZenkakuLen(_val);
        if (len > _length) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: formatMessage(MAX_ALPHA_NUM_HANKAKU, String(_length)),
          });
        } else {
          if (!_val.match(/^[0-9a-zA-Z]+$/)) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: formatMessage(MAX_ALPHA_NUM_HANKAKU, String(_length)),
            });
          }
        }
      } else {
        // 任意
        if (val) {
          const len = hankakuZenkakuLen(val);
          if (len > _length) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: formatMessage(MAX_ALPHA_NUM_HANKAKU, String(_length)),
            });
          }
          if (!val.match(/^[0-9a-zA-Z]+$/)) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: formatMessage(MAX_ALPHA_NUM_HANKAKU, String(_length)),
            });
          }
        }
      }
    });
};

/**
 * 数値入力汎用バリデーション
 * @param _required 必須
 * @param _min 最大値
 * @param _max 最小値
 * @param _place 小数点以下桁数
 * @returns z
 */
export const numberInput = (_required: boolean, _min: number, _max: number, _place: number) => {
  return z
    .string()
    .or(z.number())
    .nullable()
    .optional()
    .superRefine((val, ctx) => {
      if (_required) {
        if (val !== 0 && !val) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: formatMessage(REQUIRED),
          });
        }
      }
      if (val) {
        if (typeof val === 'string') {
          val = val.replace(/\xA5/g, '');
          val = val.replace(/\$/g, '');
          val = val.replace(/,/g, '');
        }
        const numval = Number(val);
        if (isNaN(numval)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: formatMessage(NOT_NUMBER),
          });
        } else {
          if (numval > _max || numval < _min) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: formatMessage(NUMBER_RANGE, String(_min), String(_max)),
            });
          }
          const digit = numval.toString().split('.')[1] ?? 0;
          if (_place < digit.length) {
            if (_place === 0) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: formatMessage(NUMBER_INT),
              });
            } else {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: formatMessage(NUMBER_DECIMAL_DIGITS, String(_place)),
              });
            }
          }
        }
      }
    });
};

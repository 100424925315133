import Axios from 'axios';
import { useEffect, useState } from 'react';
import { Accordion, Carousel } from 'react-bootstrap';

import { useGetSvfAccessTokenQuery } from '@/generated/graphql';
import { arrayBufferToBase64 } from '@/lib/arrayBufferToBase64';

export type PreviewProps = {
  artifactId: string;
  pages: number;
};

type PreviewImageProps = {
  preview?: PreviewProps;
  show: boolean;
};

const axios = Axios.create({
  baseURL: 'https://api.svfcloud.com/v1/artifacts/',
  responseType: 'arraybuffer',
  headers: {
    'Content-Type': 'image/png',
  },
});

export const PreviewImage = ({ preview, show }: PreviewImageProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [activeKey, setActiveKey] = useState('-1');
  const [previewImgs, setPreviewImgs] = useState<string[]>([]);

  const { data } = useGetSvfAccessTokenQuery();

  useEffect(() => {
    if (show) {
      setActiveKey('0');
    } else {
      setActiveKey('-1');
    }
  }, [show]);

  const handleShow = () => {
    if (activeKey === '0') {
      setActiveKey('-1');
    } else {
      if (preview && preview.artifactId !== '') setActiveKey('0');
    }
  };

  useEffect(() => {
    if (preview && preview.artifactId !== '' && data && data?.svfAccessToken !== '') {
      const token = data.svfAccessToken;

      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      const promises = [];

      for (let i = 1; i <= preview.pages; i++) {
        promises.push(axios.get('/' + preview.artifactId + '/' + i, {}));
        Promise.all(promises).then((values) => {
          const imgs: string[] = [];
          values.forEach((v) => {
            imgs.push(arrayBufferToBase64(v.data));
          });
          setPreviewImgs(imgs);
        });
      }
    }
  }, [preview, data]);

  return (
    <>
      <Accordion activeKey={activeKey}>
        <Accordion.Item eventKey="0">
          <Accordion.Header onClick={handleShow}>印刷プレビュー</Accordion.Header>
          <Accordion.Body>
            <Carousel>
              {previewImgs.map((img, i) => (
                <Carousel.Item key={i}>
                  <img className="m-auto" src={`data:image/png;base64,${img}`} alt={`${i}`} />
                </Carousel.Item>
              ))}
            </Carousel>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
};

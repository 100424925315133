import clsx from 'clsx';
import * as React from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

import { FieldWrapper, FieldWrapperPassThroughProps } from './FieldWrapper';

type Option = {
  label: React.ReactNode;
  value: string | number | string[];
};

type SelectFieldProps = FieldWrapperPassThroughProps & {
  options: Option[];
  className?: string;
  defaultValue?: string;
  placeholder?: string;
  registration: Partial<UseFormRegisterReturn>;
  optional?: boolean;
  disabled?: boolean;
  readonly?: boolean;
};

export const SelectField = (props: SelectFieldProps) => {
  const {
    label,
    options,
    error,
    className,
    defaultValue,
    registration,
    placeholder,
    optional,
    disabled,
    readonly,
  } = props;
  return (
    <FieldWrapper label={label} error={error}>
      <div className={readonly ? 'form-readonly-overlay' : ''}>
        <select
          placeholder={placeholder}
          name="location"
          className={clsx(
            'form-select mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md',
            className
          )}
          defaultValue={defaultValue}
          {...registration}
          disabled={disabled}
        >
          {optional ? (
            <option key="-" value="">
              -
            </option>
          ) : (
            <option hidden />
          )}
          {options.map(({ label, value }) => (
            <option key={label?.toString()} value={value}>
              {`${value}: ${label}`}
            </option>
          ))}
        </select>
      </div>
    </FieldWrapper>
  );
};

export enum PaymentClosingStatusCodeEnum {
  // 締切状態コード
  CLOSING_BEFORE = '1',
  CLOSING_AFTER = '2',
}

export const PaymentClosingStatusCode = [
  { label: '支払前', value: PaymentClosingStatusCodeEnum.CLOSING_BEFORE },
  { label: '支払済', value: PaymentClosingStatusCodeEnum.CLOSING_AFTER },
];

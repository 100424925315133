export enum ReceiveDetailTradeTypeEnum {
  // 受注種別取引
  /** 通常 */ NORMAL = '01',
  /** 値引 */ DISCOUNT = '02',
  /** 摘要 */ REMARK = '03',
  /** 消費税 */ TAX = '04',
  /** 送料*/ POSTAGE = '05',
  /** 手数料 */ CHARGE = '06',
  /** 返品 */ RETURN = '07',
}

export const ReceiveDetailTradeType = [
  { label: '通常', value: ReceiveDetailTradeTypeEnum.NORMAL },
  { label: '値引', value: ReceiveDetailTradeTypeEnum.DISCOUNT },
  { label: '摘要', value: ReceiveDetailTradeTypeEnum.REMARK },
  { label: '消費税', value: ReceiveDetailTradeTypeEnum.TAX },
  { label: '送料', value: ReceiveDetailTradeTypeEnum.POSTAGE },
  { label: '手数料', value: ReceiveDetailTradeTypeEnum.CHARGE },
  { label: '返品', value: ReceiveDetailTradeTypeEnum.RETURN },
];

export const ReturnReceiveDetailTradeType = [
  { label: '返品', value: ReceiveDetailTradeTypeEnum.RETURN },
  { label: '値引', value: ReceiveDetailTradeTypeEnum.DISCOUNT },
  { label: '摘要', value: ReceiveDetailTradeTypeEnum.REMARK },
  { label: '送料', value: ReceiveDetailTradeTypeEnum.POSTAGE },
  { label: '手数料', value: ReceiveDetailTradeTypeEnum.CHARGE },
];
export const NormalReceiveDetailTradeType = [
  { label: '通常', value: ReceiveDetailTradeTypeEnum.NORMAL },
  { label: '値引', value: ReceiveDetailTradeTypeEnum.DISCOUNT },
  { label: '摘要', value: ReceiveDetailTradeTypeEnum.REMARK },
  { label: '消費税', value: ReceiveDetailTradeTypeEnum.TAX },
  { label: '送料', value: ReceiveDetailTradeTypeEnum.POSTAGE },
  { label: '手数料', value: ReceiveDetailTradeTypeEnum.CHARGE },
];

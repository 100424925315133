export enum ImportTypeEnum {
  CREATE = '新規',
  UPDATE = '更新',
  DELETE = '削除',
}
export const IMPORT_TYPE_KEY = '更新種別';
export const _IMPORT_TYPE_KEY = 'importType';

export const ImportType = [
  { label: ImportTypeEnum.CREATE, value: '0' },
  { label: ImportTypeEnum.UPDATE, value: '1' },
  { label: ImportTypeEnum.DELETE, value: '2' },
];

import dayjs from 'dayjs';
import { sprintf } from 'sprintf-js';

// Dateをstringに変換 yyyy-mm-dd
export const toStringYyyyMmDdHyphen = (date: Date) => {
  return sprintf('%04d-%02d-%02d', date.getFullYear(), date.getMonth() + 1, date.getDate());
};

export const toStringYyyyMmDd = (date: Date) => {
  return sprintf('%04d%02d%02d', date.getFullYear(), date.getMonth() + 1, date.getDate());
};

export const toStringYyyyMmDdSlash = (date: Date) => {
  return sprintf('%04d/%02d/%02d', date.getFullYear(), date.getMonth() + 1, date.getDate());
};

export const getFirstDay = (format = 'YYYY-MM-DD') => {
  return dayjs().startOf('month').format(format);
};

export const getLastDay = (format = 'YYYY-MM-DD') => {
  return dayjs().endOf('month').format(format);
};

export const isInvalidDate = (date: Date) => Number.isNaN(date.getTime());

import { useEffect } from 'react';
import { useBlocker } from 'react-router-dom';

export const useFormGuard = (
  isDirty: boolean,
  guard: boolean,
  skipNavigateGuard: boolean | undefined,
  isSubmitSuccessful: boolean | undefined
) => {
  const message = '編集内容がリセットされます、本当にページ遷移しますか？';
  useBlocker((state) => {
    if ((skipNavigateGuard ?? false) && (isSubmitSuccessful ?? false)) return;
    const answer = window.confirm(message);
    if (answer) {
      state.retry();
    }
  }, guard && isDirty);

  const beforeUnloadhandler = (event: BeforeUnloadEvent) => {
    event.preventDefault();
    event.returnValue = message;
  };

  useEffect(() => {
    if (guard && isDirty) {
      // ブラウザの離脱を防ぐ
      window.addEventListener('beforeunload', beforeUnloadhandler);
    }
    return () => {
      window.removeEventListener('beforeunload', beforeUnloadhandler);
    };
  }, [guard, isDirty]);
};

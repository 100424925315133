import _ from 'lodash';
import { useLayoutEffect, useState } from 'react';

import { acl } from '@/components/AccessControl';
import { useAuth } from '@/lib/auth';

import { menus } from '../data/MenuData';
import { MenuGroupParam } from '../types';

export const useMenuData = () => {
  const { user } = useAuth();

  const [newMenu, setNewMenu] = useState<MenuGroupParam[]>();

  useLayoutEffect(() => {
    const allowList = acl.find((l) => user?.userType === l.role);
    if (allowList) {
      const filtered = menus
        .map((group) => {
          const allowMenu = group.menus.filter((menu) => {
            const subPaths = _.split(menu.to, '/');
            if (allowList.disallow) {
              const check = allowList.disallow.some((path) => {
                const p = _.split(path, '/');
                return subPaths[2] === p[0] && subPaths[3] === p[1];
              });
              if (check) {
                return false;
              }
            }
            return allowList.allow.some((path) => subPaths[2] === path);
          });
          if (allowMenu.length > 0) {
            return {
              groupName: group.groupName,
              menus: allowMenu,
            };
          } else {
            return undefined;
          }
        })
        .filter(Boolean);
      setNewMenu(filtered as MenuGroupParam[]);
    }
  }, [user?.userType]);

  return newMenu;
};

import { useEffect, useState } from 'react';
import Select from 'react-select';

import { useSearchReportLayoutQuery } from '@/generated/graphql';

type Option = {
  label: string;
  value: string;
};

type PrintLayoutSelectProps = {
  onChange?: (v: any) => void;
  reportLayoutDivision: string;
  reportLayoutTypeCodes?: any[] | null | undefined;
  report?: Option;
};

export const PrintLayoutSelect = ({
  onChange,
  reportLayoutDivision,
  reportLayoutTypeCodes,
  report,
}: PrintLayoutSelectProps) => {
  const { data } = useSearchReportLayoutQuery({
    reportLayoutDivision: reportLayoutDivision,
    reportLayoutTypeCodes: reportLayoutTypeCodes ?? [
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      '10',
    ],
  });
  const [options, setOptions] = useState<Option[]>([]);
  // const [defultReport, setdefultReport] = useState<Option>();

  useEffect(() => {
    if (data?.searchReportLayout) {
      const newOptions = data.searchReportLayout.edges.map((edge) => {
        const node = edge?.node;
        return {
          value: node ? node.reportLayoutCode : '',
          label: node ? node.reportLayoutName : '',
        };
      });
      setOptions(newOptions);
    }
  }, [data]);

  return (
    <Select
      options={options}
      onChange={onChange}
      defaultValue={report}
      placeholder={'帳票書式を選択してください'}
    />
  );
};

import LogRocket from 'logrocket';
import { useRoutes } from 'react-router-dom';

import { ScrollToTop } from '@/components/Navigation/ScrollToTop';
import { APP_ENV } from '@/config';
import { useAuth } from '@/lib/auth';
import { lazyImport } from '@/utils/lazyImport';

import { protectedRoutes } from './protected';
import { publicRoutes } from './public';

const { ErrorPagesRoutes } = lazyImport(() => import('@/features/errorPages'), 'ErrorPagesRoutes');

export const AppRoutes = () => {
  const auth = useAuth();

  const errorPagesRoutes = [
    {
      path: '/error/*',
      element: <ErrorPagesRoutes />,
    },
  ];

  if (APP_ENV === 'stg' && auth.user) {
    LogRocket.identify(auth.user.userCode, {
      name: auth.user.userName,
      email: auth.user.email,
    });
  }

  const routes = auth.user ? protectedRoutes : publicRoutes;

  const element = useRoutes([...errorPagesRoutes, ...routes]);

  return (
    <>
      <ScrollToTop>{element}</ScrollToTop>
    </>
  );
};

import { To } from 'history';

const BOOKMARK_KEY = 'bookmark';

const getBookmarkSet = (): Set<string> => {
  const bookmarkStr = localStorage.getItem(BOOKMARK_KEY);
  if (bookmarkStr) {
    try {
      const bookmark = JSON.parse(bookmarkStr);
      return new Set(bookmark);
    } catch {
      return new Set();
    }
  } else {
    return new Set();
  }
};

export const getBookmark = (): string[] => {
  const bmSet = getBookmarkSet();
  return Array.from(bmSet);
};

export const setBookmark = (to: To): void => {
  const bmSet = getBookmarkSet();
  bmSet.add(to.toString());
  const bookmarkStr = JSON.stringify(Array.from(bmSet));
  localStorage.setItem(BOOKMARK_KEY, bookmarkStr);
};

export const unsetBookmark = (to: To): void => {
  const bmSet = getBookmarkSet();
  bmSet.delete(to.toString());
  const bookmarkStr = JSON.stringify(Array.from(bmSet));
  localStorage.setItem(BOOKMARK_KEY, bookmarkStr);
};

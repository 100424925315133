type Edges = Array<
  | {
      node?: any;
    }
  | null
  | undefined
>;

export const edgesToPlainList = (edges: Edges | undefined | null) => {
  if (edges) {
    return edges.map((v) => v?.node);
  } else {
    return [];
  }
};

export const edgesToOptions = (edges: Edges, value: string, label: string) => {
  return edges.map((v) => labelWithValue(v?.node[value], v?.node[label]));
};

export const labelWithValue = (value: string, label: string) => {
  return {
    label: businessCodeLabel(value, label),
    value: value,
  };
};

export const businessCodeLabel = (value: string, label: string) => {
  return `${value}: ${label}`;
};

export const labelWithValueProductGroup = (value: string, code: string, label: string) => {
  return {
    label: businessCodeLabel(code, label),
    value: value,
  };
};

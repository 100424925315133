import { useMatch, useResolvedPath } from 'react-router-dom';

type RedirectAnchorProps = {
  label: string;
  navigate: string;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const RedirectAnchor = ({ label, navigate }: RedirectAnchorProps) => {
  const resolved = useResolvedPath(navigate);
  const match = useMatch({ path: resolved.pathname, end: true });
  return (
    <option
      key={navigate}
      value={navigate}
      data-navigate={navigate}
      selected={match ? true : false}
    >
      {label}
    </option>
  );
};

export enum VendorLedgerTaxTypeEnum {
  // 税種別
  INCLUDE_TAX = '1',
  EXCLUDE_TAX = '2',
  COMSUMPTION_TAX = '3',
}

export const VendorLedgerTaxType = [
  { label: '税込', value: VendorLedgerTaxTypeEnum.INCLUDE_TAX },
  { label: '税抜', value: VendorLedgerTaxTypeEnum.EXCLUDE_TAX },
  { label: '消費税', value: VendorLedgerTaxTypeEnum.COMSUMPTION_TAX },
];

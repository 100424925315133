type PrinterIndicatorProps = {
  state: string;
};

const unknownState = (
  <span style={{ color: '#b3b3b3' }} className="mr-2">
    ●
  </span>
);
const readyState = (
  <span style={{ color: '#2dc224' }} className="mr-2">
    ●
  </span>
);
const alertState = (
  <span style={{ color: '#fcbc0e' }} className="mr-2">
    ●
  </span>
);
const errorState = (
  <span style={{ color: '#fcbc0e' }} className="mr-2">
    ●
  </span>
);

const stateFormat = (state: string) => {
  switch (state) {
    case '0':
      return (
        <>
          {unknownState}
          <span>不明</span>
        </>
      );
    case '1':
      return (
        <>
          {readyState}
          <span>印刷中</span>
        </>
      );
    case '2':
      return (
        <>
          {readyState}
          <span>アイドル</span>
        </>
      );
    case '3':
      return (
        <>
          {unknownState}
          <span>節電中</span>
        </>
      );
    case '11':
      return (
        <>
          {alertState}
          <span>まもなく用紙切れ</span>
        </>
      );
    case '12':
      return (
        <>
          {alertState}
          <span>まもなくトナー切れ</span>
        </>
      );
    case '13':
      return (
        <>
          {alertState}
          <span>ウォーミングアップ中</span>
        </>
      );
    case '101':
      return (
        <>
          {errorState}
          <span>オフライン</span>
        </>
      );
    case '102':
      return (
        <>
          {errorState}
          <span>用紙切れ</span>
        </>
      );
    case '103':
      return (
        <>
          {errorState}
          <span>ジャム</span>
        </>
      );
    case '104':
      return (
        <>
          {errorState}
          <span>トナー切れ</span>
        </>
      );
    case '105':
      return (
        <>
          {errorState}
          <span>ビジー</span>
        </>
      );
    case '106':
      return (
        <>
          {errorState}
          <span>カバーオープン</span>
        </>
      );
    case '107':
      return (
        <>
          {errorState}
          <span>サービスコール</span>
        </>
      );
    default:
      return <></>;
  }
};

export const PrinterIndicator = ({ state }: PrinterIndicatorProps) => {
  return <>{stateFormat(state)}</>;
};

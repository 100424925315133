export enum ProductDeliveryStatusEnum {
  DELIVER_NOT_FINISH = '1',
  DELIVER_FINISH = '2',
  BACK_WAITING = '3',
  BACK_FINISH = '4',
}

export const ProductDeliveryStatus = [
  { label: '未納', value: ProductDeliveryStatusEnum.DELIVER_NOT_FINISH },
  { label: '完納', value: ProductDeliveryStatusEnum.DELIVER_FINISH },
  { label: '返品待', value: ProductDeliveryStatusEnum.BACK_WAITING },
  { label: '返品済', value: ProductDeliveryStatusEnum.BACK_FINISH },
];

import { BusinessCodeList } from '@/business_code/types';

type BusinessCodeLabelProps = {
  codes: BusinessCodeList;
  value: string | undefined;
};

export const BusinessCodeLabel = ({ codes, value }: BusinessCodeLabelProps) => {
  const code = codes.filter((c) => c.value === value);
  return <>{code && code.length ? <div>{code[0].label}</div> : ''}</>;
};

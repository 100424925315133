import clsx from 'clsx';
import { UseFormRegisterReturn } from 'react-hook-form';

import { FieldWrapper, FieldWrapperPassThroughProps } from './FieldWrapper';

type InputFieldProps = FieldWrapperPassThroughProps & {
  type?: 'text' | 'email' | 'password';
  className?: string;
  registration: Partial<UseFormRegisterReturn>;
  defaultValue?: string;
  placeholder?: string;
  maxLength?: number;
  readOnly?: boolean;
  disabled?: boolean;
};

export const InputField = (props: InputFieldProps) => {
  const {
    type = 'text',
    label,
    className,
    registration,
    error,
    defaultValue,
    placeholder,
    maxLength,
    readOnly = false,
    disabled,
  } = props;
  return (
    <FieldWrapper label={label} error={error}>
      <input
        type={type}
        className={clsx('form-control', className)}
        placeholder={placeholder}
        defaultValue={defaultValue}
        {...registration}
        maxLength={maxLength}
        readOnly={readOnly}
        disabled={disabled}
      />
    </FieldWrapper>
  );
};

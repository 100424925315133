import { GraphQLClient, RequestDocument, Variables } from 'graphql-request';
import _ from 'lodash';

import { BACKEND_URI } from '@/config';
import { getJWTToken } from '@/features/auth';

import { ConnectionError, APIError } from './errors';

export const graphqlHeaders = {};

class CustomGraphQLClient extends GraphQLClient {
  request<T = any, V = Variables>(document: RequestDocument, variables?: V): Promise<T> {
    return new Promise((resolve, reject) => {
      const array = new Uint32Array(1);
      const rand = window.crypto.getRandomValues(array);
      getJWTToken()
        .then((token) => {
          super.setHeader('authorization', `Bearer ${token}`);
          super.setHeader('requestid', `${rand[0]}`);
          super
            .request(document, variables)
            .then((d) => {
              resolve(d);
            })
            .catch((e) => {
              if (e.response) {
                reject(
                  new APIError(_.get(e.response, 'errors[0].message'), _.get(e.response, 'errors'))
                );
              } else {
                reject(new ConnectionError(e.message));
              }
            });
        })
        .catch((e) => reject(e));
    });
  }
}

export const graphqlClient = new CustomGraphQLClient(BACKEND_URI);

export const useFetchData = <TData, TVariables>(query: string): (() => Promise<TData>) => {
  return (variables?: TVariables) => {
    return graphqlClient.request(query, variables);
  };
};

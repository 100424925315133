export enum ArrivalTradeTypeEnum {
  /** 通常 */ NORMAL = '01',
  /** 摘要 */ REMARK = '02',
  /** 返品(良品) */ RETURN_NOT_DAMAGED = '03',
  /** 返品(不良品) */ RETURN_DAMAGED = '04',
  /** 送料 */ POSTAGE = '05',
  /** 手数料 */ CHARGE = '06',
  /** 値引 */ DISCOUNT = '07',
  /** 交換 */ CHANGED = '08',
  /** 消費税 */ TAX = '09',
}

export const NormalArrivalTradeType = [
  { label: '通常', value: ArrivalTradeTypeEnum.NORMAL },
  { label: '摘要', value: ArrivalTradeTypeEnum.REMARK },
  { label: '送料', value: ArrivalTradeTypeEnum.POSTAGE },
  { label: '手数料', value: ArrivalTradeTypeEnum.CHARGE },
  { label: '値引', value: ArrivalTradeTypeEnum.DISCOUNT },
  { label: '返品(良品)', value: ArrivalTradeTypeEnum.RETURN_NOT_DAMAGED },
  { label: '返品(不良品)', value: ArrivalTradeTypeEnum.RETURN_DAMAGED },
  { label: '消費税', value: ArrivalTradeTypeEnum.TAX },
];

export const ReturnArrivalTradeType = [
  { label: '返品(良品)', value: ArrivalTradeTypeEnum.RETURN_NOT_DAMAGED },
  { label: '返品(不良品)', value: ArrivalTradeTypeEnum.RETURN_DAMAGED },
  { label: '摘要', value: ArrivalTradeTypeEnum.REMARK },
  { label: '送料', value: ArrivalTradeTypeEnum.POSTAGE },
  { label: '手数料', value: ArrivalTradeTypeEnum.CHARGE },
  { label: '値引', value: ArrivalTradeTypeEnum.DISCOUNT },
];

export const ChangedArrivalTradeType = [
  { label: '交換', value: ArrivalTradeTypeEnum.CHANGED },
  { label: '摘要', value: ArrivalTradeTypeEnum.REMARK },
];

export const ArrivalTradeType = [
  { label: '通常', value: ArrivalTradeTypeEnum.NORMAL },
  { label: '返品(良品)', value: ArrivalTradeTypeEnum.RETURN_NOT_DAMAGED },
  { label: '返品(不良品)', value: ArrivalTradeTypeEnum.RETURN_DAMAGED },
  { label: '交換', value: ArrivalTradeTypeEnum.CHANGED },
  { label: '摘要', value: ArrivalTradeTypeEnum.REMARK },
  { label: '送料', value: ArrivalTradeTypeEnum.POSTAGE },
  { label: '手数料', value: ArrivalTradeTypeEnum.CHARGE },
  { label: '値引', value: ArrivalTradeTypeEnum.DISCOUNT },
  { label: '消費税', value: ArrivalTradeTypeEnum.TAX },
];

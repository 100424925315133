export enum SupplierEnum {
  OUTSOURCER = '1',
  PURCHASE = '2',
  OWN = '3',
}

export const Supplier = [
  {
    label: '他社商品(買取)',
    value: SupplierEnum.OUTSOURCER,
  },
  {
    label: '他社商品(委託)',
    value: SupplierEnum.PURCHASE,
  },
  {
    label: '自社商品',
    value: SupplierEnum.OWN,
  },
];

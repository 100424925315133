import { ReactNode, useState } from 'react';

import { Button, ExportMapping } from '@/components/Elements';
import { MappingFilter } from '@/components/Elements/Export/MappingFilter';

type FilteredExportButtonProps = {
  onClick: (filteredMapping: ExportMapping[]) => void;
  mapping: ExportMapping[];
  isLoading: boolean;
  children: ReactNode;
  cacheKey: string;
};

export const FilteredExportButton = ({
  onClick,
  mapping,
  isLoading,
  children,
}: FilteredExportButtonProps) => {
  const [filteredMapping, setFilteredMapping] = useState(mapping);
  const [show, setShow] = useState(false);
  return (
    <>
      <div className="btn-group me-2">
        <Button
          isLoading={isLoading}
          variant="secondary"
          onClick={() => {
            onClick(filteredMapping);
          }}
        >
          {children}
        </Button>
        <button
          className="btn btn-outline-secondary"
          onClick={() => {
            setShow(true);
          }}
        >
          <i className="fas fa-cog" />
        </button>
      </div>
      <MappingFilter
        mapping={mapping}
        onFiltered={setFilteredMapping}
        onHide={() => {
          setShow(false);
        }}
        show={show}
      />
    </>
  );
};

export enum ArrivalDetailStatusEnum {
  /** 未入荷 */ NOT_DELIVER = '1',
  /** 全数入荷 */ DELIVER_FINISH = '2',
  /** 一部入荷 */ DELIVER_NOT_FINISH = '3',
  /** 訂正入荷 */ CORRECTION = '4',
  /** 発注取消 */ CANCEL = '5',
}

export const ArrivalDetailStatus = [
  { label: '未入荷', value: ArrivalDetailStatusEnum.NOT_DELIVER },
  { label: '全数入荷', value: ArrivalDetailStatusEnum.DELIVER_FINISH },
  { label: '一部入荷', value: ArrivalDetailStatusEnum.DELIVER_NOT_FINISH },
  { label: '訂正入荷', value: ArrivalDetailStatusEnum.CORRECTION },
  { label: '発注取消', value: ArrivalDetailStatusEnum.CANCEL },
];

export const CashArrivalDetailStatus = [
  { label: '未入荷', value: ArrivalDetailStatusEnum.NOT_DELIVER },
  { label: '全数入荷', value: ArrivalDetailStatusEnum.DELIVER_FINISH },
  { label: '一部入荷', value: ArrivalDetailStatusEnum.DELIVER_NOT_FINISH },
];

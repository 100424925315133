export enum APIErrorMessage {
  VERSION_ERROR = 'VERSION_ERROR',
  PRIMARY_KEY_NOT_MATCH = 'PRIMARY_KEY_NOT_MATCH',
  PRIMARY_KEY_EXISTS = 'PRIMARY_KEY_EXISTS',
  RECORD_LOCK_ERROR = 'RECORD_LOCK_ERROR',
  CLOSED_NOT_UPDATE = 'CLOSED_NOT_UPDATE',
  SVF_CONNECTION_ERROR = 'SVF_CONNECTION_ERROR',
  AUTHORIZATION_ERROR = 'AUTHORIZATION_ERROR',
  WITHDRAWAL_NOT_CONFIRMED_ERROR = 'WITHDRAWAL_NOT_CONFIRMED_ERROR',
  WITHDRAWAL_CONFIRMED_ERROR = 'WITHDRAWAL_CONFIRMED_ERROR',
  REPORT_COUNT_TOO_MANY_ERROR = 'REPORT_COUNT_TOO_MANY_ERROR',
  STOCK_NOT_ALLOWABLE = 'STOCK_NOT_ALLOWABLE',
  DUPLICATE_PERIODS_ERROR = 'DUPLICATE_PERIODS_ERROR',
  CREATE_WITHDRAWAL_ERROR = 'CREATE_WITHDRAWAL_ERROR',
  PAYMENT_DECISION_ERROR = 'PAYMENT_DECISION_ERROR',
  SENDORDER_DELETE_ERROR = 'SENDORDER_DELETE_ERROR',
  SENDORDER_PAYMENT_DELETE_ERROR = 'SENDORDER_PAYMENT_DELETE_ERROR',
  RECIEVEORDER_DEMAND_DELETE_ERROR = 'RECIEVEORDER_DEMAND_DELETE_ERROR',
  WITHDRAWAL_DELETE_CONFIRMED_ERROR = 'WITHDRAWAL_DELETE_CONFIRMED_ERROR',
  PAYMENT_TAX_ADJUST_ERROR = 'PAYMENT_TAX_ADJUST_ERROR',
  CLOSING_CANCEL_ERROR = 'CLOSING_CANCEL_ERROR',
  DEPOSIT_ZERO_DEMANDS_ERROR = 'DEPOSIT_ZERO_DEMANDS_ERROR',
  SALES_DEMAND_PRINT_ERROR = 'SALES_DEMAND_PRINT_ERROR',
  CUSTOMER_SALESTRADEDVISION_EDIT_ERROR = 'CUSTOMER_SALESTRADEDVISION_EDIT_ERROR',
  RETURN_SHIPMENT_UPDATE_ERROR = 'RETURN_SHIPMENT_UPDATE_ERROR',
}

export class ConnectionError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = 'ConnectionError';
  }
}

export interface Error {
  message: string;
  locations?: {
    line: number;
    column: number;
  }[];
  path?: string[];
  extensions?: any;
}

export class APIError extends Error {
  errors: Error[];
  constructor(message?: string, errors?: Error[]) {
    super(message);
    this.name = 'APIError';
    if (errors) {
      this.errors = errors;
    } else {
      this.errors = [];
    }
  }
}

export enum SalesTaxTypeEnum {
  // 得意先税転嫁
  /** 外税/伝票計 */ EXCLUDING_TAX_SLIP = '01',
  /** 外税/請求時 */ EXCLUDING_TAX_DEMAND = '02',
  /** 外税/請求時調整 */ EXCLUDING_TAX_DEMAND_ADJUST = '03',
  /** 内税 */ INCLUDED_TAX = '04',
  /** 内税/総額 */ INCLUDED_TAX_TOTAL = '05',
  /** 内税/請求時 */ INCLUDED_TAX_DEMAND = '06',
  /** 輸出(免税) */ EXPORT_TAX_FREE = '07',
  /** 外税/手入力 */ EXCLUDING_TAX_INPUT = '08',
}

export const SalesTaxType = [
  { label: '外税/伝票計', value: SalesTaxTypeEnum.EXCLUDING_TAX_SLIP },
  { label: '外税/請求時', value: SalesTaxTypeEnum.EXCLUDING_TAX_DEMAND },
  // { label: '外税/請求時調整', value: SalesTaxTypeEnum.EXCLUDING_TAX_DEMAND_ADJUST },
  { label: '内税', value: SalesTaxTypeEnum.INCLUDED_TAX },
  { label: '内税/総額', value: SalesTaxTypeEnum.INCLUDED_TAX_TOTAL },
  { label: '内税/請求時', value: SalesTaxTypeEnum.INCLUDED_TAX_DEMAND },
  { label: '輸出(免税)', value: SalesTaxTypeEnum.EXPORT_TAX_FREE },
  { label: '外税/手入力', value: SalesTaxTypeEnum.EXCLUDING_TAX_INPUT },
];

import clsx from 'clsx';
import { useMemo } from 'react';
import { Accept, useDropzone } from 'react-dropzone';

const baseStyle: React.CSSProperties = {
  flex: 1,
  display: 'flex',
  minHeight: '120px',
  margin: '10px',
  flexDirection: 'column',
  alignItems: 'center',
  paddingTop: '34px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const activeStyle: React.CSSProperties = {
  borderColor: '#2196f3',
};

const acceptStyle: React.CSSProperties = {
  borderColor: '#00e676',
};

const rejectStyle: React.CSSProperties = {
  borderColor: '#ff1744',
};

type DropzoneProps = {
  onDrop: (files: File[]) => void;
  accept?: Accept | undefined;
};

export const SingleDropzone = ({ onDrop, accept }: DropzoneProps) => {
  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, acceptedFiles } =
    useDropzone({
      maxFiles: 1,
      onDrop,
      accept: accept,
    });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  return (
    <>
      {acceptedFiles.length > 0 ? (
        <div className={clsx('m-2.5')}>
          <div className={clsx('h-[100px]')}>
            {acceptedFiles.map((file) => (
              <div key={file.name}>{file.name}</div>
            ))}
          </div>
        </div>
      ) : (
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <i className="fas fa-cloud-upload-alt fa-2x" />
          <p>ファイルをドラッグしてください</p>
        </div>
      )}
    </>
  );
};

import { CognitoUser } from 'amazon-cognito-identity-js';
import { useState } from 'react';
import { Alert } from 'react-bootstrap';
import * as z from 'zod';

import { Button } from '@/components/Elements';
import { Form, InputField } from '@/components/Form';
import { useAuth } from '@/lib/auth';
import { PASSWORD_NOT_MATCH, REQUIRED } from '@/lib/message';
import { zodPasswordPolicy } from '@/lib/zod-definition';

import { NewPasswordRequired } from '../exceptions/NewPasswordRequired';

const loginSchema = z.object({
  userId: z.string().min(1, REQUIRED),
  password: z.string().min(1, REQUIRED),
});

const passwordChangeSchema = z
  .object({
    password: zodPasswordPolicy(),
    confirm: zodPasswordPolicy(),
  })
  .refine((data) => data.password === data.confirm, {
    message: PASSWORD_NOT_MATCH,
    path: ['confirm'],
  });

type LoginValues = {
  userId: string;
  password: string;
};

type LoginFormProps = {
  onSuccess: () => void;
};

type PasswordChange = {
  password: string;
  confirm: string;
};

type PasswordRequired = {
  cognitoUser: CognitoUser;
  userAttributes: any;
};

export const LoginForm = ({ onSuccess }: LoginFormProps) => {
  const { login, isLoggingIn, register } = useAuth();

  const [failed, setFailed] = useState(false);
  const [newPasswordRequired, setNewPasswordRequired] = useState<PasswordRequired>();

  return (
    <div>
      {!newPasswordRequired && (
        <Form<LoginValues, typeof loginSchema>
          onSubmit={async (values) => {
            setFailed(false);
            try {
              await login(values);
              onSuccess();
            } catch (e) {
              if (e instanceof NewPasswordRequired) {
                setNewPasswordRequired({
                  cognitoUser: e.cognitoUser,
                  userAttributes: e.userAttributes,
                });
              } else {
                setFailed(true);
              }
            }
          }}
          schema={loginSchema}
        >
          {({ register, formState }) => (
            <>
              {failed && <Alert variant="danger">ログインに失敗しました</Alert>}
              <div className="mb-4">
                <h5>担当者コード</h5>
                <InputField
                  type="email"
                  error={formState.errors['userId']}
                  registration={register('userId')}
                />
              </div>
              <div className="mb-5">
                <h5>パスワード</h5>
                <InputField
                  type="password"
                  error={formState.errors['password']}
                  registration={register('password')}
                />
              </div>
              <div className="text-center">
                <Button isLoading={isLoggingIn} type="submit">
                  ログイン
                </Button>
              </div>
            </>
          )}
        </Form>
      )}
      {newPasswordRequired && (
        <Form<PasswordChange, typeof passwordChangeSchema>
          onSubmit={async (values) => {
            setFailed(false);
            try {
              await register({
                cognitoUser: newPasswordRequired.cognitoUser,
                userAttributes: newPasswordRequired.userAttributes,
                newPassword: values.password,
              });
              onSuccess();
            } catch (e) {
              setFailed(true);
            }
          }}
          schema={passwordChangeSchema}
        >
          {({ register, formState }) => (
            <>
              <Alert variant="info">初回ログインのためパスワード変更をしてください。</Alert>
              <div className="mb-4">
                <h5>パスワード</h5>
                <InputField
                  type="password"
                  error={formState.errors['password']}
                  registration={register('password')}
                />
              </div>
              <div className="mb-5">
                <h5>パスワード(確認用)</h5>
                <InputField
                  type="password"
                  error={formState.errors['confirm']}
                  registration={register('confirm')}
                />
              </div>
              <div className="text-center">
                <Button isLoading={isLoggingIn} type="submit">
                  パスワード変更
                </Button>
              </div>
            </>
          )}
        </Form>
      )}
    </div>
  );
};

import { UserResponse } from '..';

import { authenticateUser, getLoginUser } from './cognito';

export type LoginCredentialsDTO = {
  userId: string;
  password: string;
};

export const loginWithUserIdAndPassword = (data: LoginCredentialsDTO): Promise<UserResponse> => {
  return new Promise((resolve, reject) => {
    authenticateUser(data)
      .then(({ idToken, refreshToken }) => {
        getLoginUser()
          .then((user) => {
            resolve({
              idToken: idToken,
              refreshToken: refreshToken,
              user: user,
            });
          })
          .catch(reject);
      })
      .catch(reject);
  });
};

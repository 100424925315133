export enum PurchaseVoucherTypeEnum {
  PURCHASE_VOUCHER = '1', //仕入伝票
  IMPORT_VOUCHER = '2', // 取込伝票
  PP_VOUCHER = '3', // 仕掛品伝票
  PAYMENT_VOUCHER = '4', // 支払伝票（払締時消費税）
  ADJUST_TAX_VOUCHER = '5', // 消費税調整伝票
}

export const PurchaseVoucherType = [
  { label: '仕入伝票', value: PurchaseVoucherTypeEnum.PURCHASE_VOUCHER },
  { label: '取込伝票', value: PurchaseVoucherTypeEnum.IMPORT_VOUCHER },
  { label: '仕掛品伝票', value: PurchaseVoucherTypeEnum.PP_VOUCHER },
  { label: '支払伝票（払締時消費税）', value: PurchaseVoucherTypeEnum.PAYMENT_VOUCHER },
  { label: '消費税調整伝票', value: PurchaseVoucherTypeEnum.ADJUST_TAX_VOUCHER },
];

export enum UserTypeEnum {
  SALE_STOCK = '1',
  SALES = '2',
  MANAGEMENT = '3',
  CLOTH = '4',
  DIRECT = '5',
  PLANNER = '6',
  DESIGNER = '7',
}
export const UserType = [
  {
    label: '販売・在庫',
    value: UserTypeEnum.SALE_STOCK,
  },
  {
    label: '営業',
    value: UserTypeEnum.SALES,
  },
  {
    label: '経営管理',
    value: UserTypeEnum.MANAGEMENT,
  },
  {
    label: '布工房',
    value: UserTypeEnum.CLOTH,
  },
  {
    label: '直営店',
    value: UserTypeEnum.DIRECT,
  },
  {
    label: '商品企画',
    value: UserTypeEnum.PLANNER,
  },
  {
    label: 'デザイナー',
    value: UserTypeEnum.DESIGNER,
  },
];

import _ from 'lodash';
import { ReactNode, useLayoutEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useAuth } from '@/lib/auth';

import { acl } from './acl';
import { Forbidden } from './Forbidden';

export const AccessControl = ({ children }: { children: ReactNode }) => {
  const { user } = useAuth();

  const location = useLocation();

  const [hasAuthority, setHasAuthority] = useState<boolean>();

  useLayoutEffect(() => {
    const allowList = acl.find((l) => user?.userType === l.role);
    if (allowList) {
      const paths = _.split(location.pathname, '/app/');

      if (paths.length <= 1) {
        setHasAuthority(true);
      } else {
        const subPaths = _.split(paths[1], '/');

        if (allowList.allow.includes(subPaths[0])) {
          setHasAuthority(true);
        } else {
          setHasAuthority(false);
        }
      }
    }
  }, [location.pathname, user?.userType]);

  // return <>{children}</>;

  return (
    <>{hasAuthority === undefined ? <></> : hasAuthority === false ? <Forbidden /> : children}</>
  );
};

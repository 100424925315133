import { Navigate, Route, Routes } from 'react-router-dom';

import { Login } from './Login';

export const AuthRoutes = () => {
  return (
    <Routes>
      <Route path="login" element={<Login />} />
    </Routes>
  );
};

export const NotAuthRoutes = () => {
  return <Navigate to="/auth/login" />;
};

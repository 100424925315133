export const parseFormat = (e: string): [string | undefined, boolean] => {
  const parse = (e && e.match(/("(.*?)"\s*)?([a-z]+)\s*(\d*)\s*(,*)(\s*"(.*?)"\s*)?(.*)/i)) || [];
  if (parse.length > 0) {
    parse[3];
    const type = parse[3];
    const scale = parseInt(parse[4]);

    let unit = '¥';
    if (parse[8] && parse[8].length > 0) {
      unit = parse[8];
    }

    let scaleFmtStr = '';
    if (!isNaN(scale)) {
      scaleFmtStr = `.${'0'.repeat(scale)}`;
    }
    let format = '';
    let result = false;
    switch (type) {
      case 'n':
        // 数値
        format = '0';
        result = true;
        break;
      case 'f':
        // 固定小数点
        result = true;
        break;
      case 'd':
        // 整数
        result = true;
        break;
      case 'c':
        // 通貨
        // ¥#,##0;¥-#,##0
        format = `${unit}#,##0${scaleFmtStr};${unit}-#,##0${scaleFmtStr}`;
        result = true;
        break;
      case 'p':
        // パーセンテージ
        format = `0${scaleFmtStr}%`;
        result = true;
        break;
    }
    return [format, result];
  } else {
    return [undefined, false];
  }
};

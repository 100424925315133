export enum TradeStopFlgEnum {
  // 取引フラグ
  CONTINUE = '0',
  STOP = '1',
}

export const TradeStopFlg = [
  { label: '取引中', value: TradeStopFlgEnum.CONTINUE },
  { label: '取引停止', value: TradeStopFlgEnum.STOP },
];

export enum ClearingStateEnum {
  // 消込状態
  /** 未消込 */ NOT_CLEARING = '0',
  /** 一部消込 */ PART_CLEARING = '1',
  /** 消込済 */ CLEARED = '2',
}

export const ClearingState = [
  { label: '未消込', value: ClearingStateEnum.NOT_CLEARING },
  { label: '一部消込', value: ClearingStateEnum.PART_CLEARING },
  { label: '消込済', value: ClearingStateEnum.CLEARED },
];

export enum DemandClosingGroupCodeEnum {
  // 締グループ
  FIVE = '05',
  TEN = '10',
  FIFTEEN = '15',
  TWENTY = '20',
  TWENTY_FIVE = '25',
  THIRTY = '31',
  OTHER = '99',
}

export const DemandClosingGroupCode = [
  { label: '毎月5日締', value: DemandClosingGroupCodeEnum.FIVE },
  { label: '毎月10日締', value: DemandClosingGroupCodeEnum.TEN },
  { label: '毎月15日締', value: DemandClosingGroupCodeEnum.FIFTEEN },
  { label: '毎月20日締', value: DemandClosingGroupCodeEnum.TWENTY },
  { label: '毎月25日締', value: DemandClosingGroupCodeEnum.TWENTY_FIVE },
  { label: '毎月末日締', value: DemandClosingGroupCodeEnum.THIRTY },
  { label: 'その他', value: DemandClosingGroupCodeEnum.OTHER },
];

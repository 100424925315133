import * as React from 'react';

import logo from '@/assets/folkart.svg';
import { Head } from '@/components/Head';

type LayoutProps = {
  children: React.ReactNode;
  title: string;
};

export const Layout = ({ children, title }: LayoutProps) => {
  return (
    <>
      <Head title={title} />
      <div className="card shadow-sm fk__login-card">
        <div className="card-body">
          <div className="text-center mb-5">
            <img className="fk__login-logo" src={logo} alt="logo" />
          </div>
          {children}
        </div>
      </div>
    </>
  );
};

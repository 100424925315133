export enum DivistionTypeEnum {
  NORMAL = '001',
  DIRECT = '002',
  OTHER = '009',
}

export const DivistionType = [
  { label: '通常', value: DivistionTypeEnum.NORMAL },
  { label: '直営店', value: DivistionTypeEnum.DIRECT },
  { label: 'その他', value: DivistionTypeEnum.OTHER },
];

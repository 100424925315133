export enum PurposeGroupEnum {
  DOMESTIC = '1',
  OVERSEAS = '2',
  DIRECT = '3',
  CLOTH = '4',
}

export const PurposeGroup = [
  { label: '国内', value: PurposeGroupEnum.DOMESTIC },
  { label: '海外', value: PurposeGroupEnum.OVERSEAS },
  { label: '社内', value: PurposeGroupEnum.DIRECT },
  { label: '生産', value: PurposeGroupEnum.CLOTH },
];

export const PurposeGroup1 = [
  { label: '国内', value: PurposeGroupEnum.DOMESTIC },
  { label: '海外', value: PurposeGroupEnum.OVERSEAS },
  { label: '社内', value: PurposeGroupEnum.DIRECT },
];

import { useState, useEffect, Suspense } from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import { Button, Spinner } from '@/components/Elements';
import { NODE_ENV } from '@/config';
import { AuthProvider } from '@/lib/auth';
import { queryClient } from '@/lib/react-query';

const ErrorFallback = ({ error }: FallbackProps) => {
  const [errMessage, setErrMessage] = useState('');
  useEffect(() => {
    let err = 'エラーが発生しました。画面を更新してください。';
    // バージョンアップエラー
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (error?.message && chunkFailedMessage.test(error.message)) {
      err = 'バージョンが更新されました。再読込します。';
      window.location.reload();
    }
    // 認証エラー
    const authErrMessage = /COGNITO_AUTH_ERROR/;
    if (error?.message && authErrMessage.test(error.message)) {
      err = '認証情報切れました。再度ログインしてください。';
      window.location.assign(window.location.origin);
    }
    setErrMessage(err);
  }, [error]);
  return (
    <div
      className="text-red-500 w-screen h-screen flex flex-col justify-center items-center"
      role="alert"
    >
      <h2 className="text-lg font-semibold">{errMessage}</h2>
      <Button className="mt-4" onClick={() => window.location.assign(window.location.origin)}>
        更新
      </Button>
    </div>
  );
};

type AppProviderProps = {
  children: React.ReactNode;
};

export const AppProvider = ({ children }: AppProviderProps) => {
  return (
    <>
      <ToastContainer autoClose={5000} hideProgressBar />
      <Suspense
        fallback={
          <div className="flex items-center justify-center w-screen h-screen">
            <Spinner size="xl" />
          </div>
        }
      >
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <HelmetProvider>
            <QueryClientProvider client={queryClient}>
              {NODE_ENV !== 'test' && <ReactQueryDevtools />}
              <AuthProvider>
                <Router>{children}</Router>
              </AuthProvider>
            </QueryClientProvider>
          </HelmetProvider>
        </ErrorBoundary>
      </Suspense>
    </>
  );
};

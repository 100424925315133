/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import { Control, Controller, FieldValues, UseFormRegisterReturn } from 'react-hook-form';

import { FieldWrapper, FieldWrapperPassThroughProps } from './FieldWrapper';

type ToggleCheckboxFieldProps = FieldWrapperPassThroughProps & {
  className?: string;
  name: string;
  control: Control<any>;
  optionLabel: string;
};

export const ToggleCheckboxField = (props: ToggleCheckboxFieldProps) => {
  const { label, error, className, name, control, optionLabel } = props;
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <FieldWrapper label={label} error={error}>
            <div className="row row-cols-auto">
              <div className="col">
                <div className="form-check fk__alt-form-control fk__alt-form-control__label">
                  <input
                    type="checkbox"
                    className={clsx('form-check-input', className)}
                    checked={field.value === '1' ? true : false}
                    id={`${name}`}
                    onChange={(e) => {
                      field.onChange(e.target.checked ? '1' : '0');
                    }}
                  />
                  <label className="form-check-label" htmlFor={`${name}`}>
                    {optionLabel}
                  </label>
                </div>
              </div>
            </div>
          </FieldWrapper>
        );
      }}
    />
  );
};

import { Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { AccessControl } from '@/components/AccessControl';
import { Spinner } from '@/components/Elements';
import { Layout } from '@/components/Layout';
import { lazyImport } from '@/utils/lazyImport';

const { Dashboard } = lazyImport(() => import('@/features/dashboard'), 'Dashboard');
const { ProductRoutes } = lazyImport(() => import('@/features/products'), 'ProductRoutes');
const { CustomerRoutes } = lazyImport(() => import('@/features/customers'), 'CustomerRoutes');
const { VendorRoutes } = lazyImport(() => import('@/features/vendors'), 'VendorRoutes');
const { DeliverRoutes } = lazyImport(() => import('@/features/delivers'), 'DeliverRoutes');
const { DivisionRoutes } = lazyImport(() => import('@/features/divisions'), 'DivisionRoutes');
const { WarehouseMoveRoutes } = lazyImport(
  () => import('@/features/warehouse_move'),
  'WarehouseMoveRoutes'
);
const { WarehouseRoutes } = lazyImport(() => import('@/features/warehouses'), 'WarehouseRoutes');
const { DemandRoutes } = lazyImport(() => import('@/features/demands'), 'DemandRoutes');
const { PaymentRoutes } = lazyImport(() => import('@/features/payments'), 'PaymentRoutes');
const { ProductGroupRoutes } = lazyImport(
  () => import('@/features/product_groups'),
  'ProductGroupRoutes'
);
const { EstimateRoutes } = lazyImport(() => import('@/features/estimates'), 'EstimateRoutes');
const { CustomerLedgerRoutes } = lazyImport(
  () => import('@/features/customer_ledger'),
  'CustomerLedgerRoutes'
);
const { VendorLedgerRoutes } = lazyImport(
  () => import('@/features/vendor_ledger'),
  'VendorLedgerRoutes'
);
const { ReceiveOrderRoutes } = lazyImport(
  () => import('@/features/receive_orders'),
  'ReceiveOrderRoutes'
);
const { SendOrderRoutes } = lazyImport(() => import('@/features/send_orders'), 'SendOrderRoutes');
const { ArrivalRoutes } = lazyImport(() => import('@/features/arrival'), 'ArrivalRoutes');
const { ComponentRoutes } = lazyImport(() => import('@/features/components'), 'ComponentRoutes');
const { CustomerBudgetRoutes } = lazyImport(
  () => import('@/features/customer_budgets'),
  'CustomerBudgetRoutes'
);
const { UserRoutes } = lazyImport(() => import('@/features/users'), 'UserRoutes');
const { DepositRoutes } = lazyImport(() => import('@/features/deposits'), 'DepositRoutes');
const { DepositDailyRoutes } = lazyImport(
  () => import('@/features/deposit_daily'),
  'DepositDailyRoutes'
);
const { ShipmentRoutes } = lazyImport(() => import('@/features/shipment'), 'ShipmentRoutes');
const { WithdrawalRoutes } = lazyImport(() => import('@/features/withdrawals'), 'WithdrawalRoutes');
const { WithdrawalDetailRoutes } = lazyImport(
  () => import('@/features/withdrawal_detail'),
  'WithdrawalDetailRoutes'
);
const { SalesUnitPriceUpdateRoutes } = lazyImport(
  () => import('@/features/sales_unit_price_update'),
  'SalesUnitPriceUpdateRoutes'
);
const { SendOrderLotRoutes } = lazyImport(
  () => import('@/features/send_order_lot'),
  'SendOrderLotRoutes'
);
const { OutsourcerSalesRoutes } = lazyImport(
  () => import('@/features/outsourcer_sales'),
  'OutsourcerSalesRoutes'
);
const { TrustInventoryRoutes } = lazyImport(
  () => import('@/features/trust_inventory'),
  'TrustInventoryRoutes'
);
const { DirectlyPurchaseListRoutes } = lazyImport(
  () => import('@/features/directly_purchase_list'),
  'DirectlyPurchaseListRoutes'
);
const { CustomerPriceRoutes } = lazyImport(
  () => import('@/features/customer_price'),
  'CustomerPriceRoutes'
);
const { SalesTrustRoutes } = lazyImport(
  () => import('@/features/sales_trusts'),
  'SalesTrustRoutes'
);

const { ReceivableBalanceRoutes } = lazyImport(
  () => import('@/features/receivable_balance'),
  'ReceivableBalanceRoutes'
);

const { PayableBalanceRoutes } = lazyImport(
  () => import('@/features/payable_balance'),
  'PayableBalanceRoutes'
);

const { DepositDetailRoutes } = lazyImport(
  () => import('@/features/deposit_detail'),
  'DepositDetailRoutes'
);

const { ReceivedOrderDetailRoutes } = lazyImport(
  () => import('@/features/received_order_detail'),
  'ReceivedOrderDetailRoutes'
);

const { SalesDetailRoutes } = lazyImport(
  () => import('@/features/sales_detail'),
  'SalesDetailRoutes'
);

const { PurchaseOrderDetailRoutes } = lazyImport(
  () => import('@/features/purchase_order_detail'),
  'PurchaseOrderDetailRoutes'
);

const { PurchaseDetailRoutes } = lazyImport(
  () => import('@/features/purchase_detail'),
  'PurchaseDetailRoutes'
);

const { PurchaseDailyRoutes } = lazyImport(
  () => import('@/features/purchase_daily'),
  'PurchaseDailyRoutes'
);

const { PurchaseAnalyticsRoutes } = lazyImport(
  () => import('@/features/purchase_analytics'),
  'PurchaseAnalyticsRoutes'
);

const { ProductionRoutes } = lazyImport(() => import('@/features/production'), 'ProductionRoutes');

const { ProductionDetailRoutes } = lazyImport(
  () => import('@/features/production_detail'),
  'ProductionDetailRoutes'
);

const { ClothProgressRoutes } = lazyImport(
  () => import('@/features/cloth_progress'),
  'ClothProgressRoutes'
);

const { StockAdjustRoutes } = lazyImport(
  () => import('@/features/stock_adjust'),
  'StockAdjustRoutes'
);

const { StockListRoutes } = lazyImport(() => import('@/features/stock_list'), 'StockListRoutes');

const { StockAnalyticsRoutes } = lazyImport(
  () => import('@/features/stock_analytics'),
  'StockAnalyticsRoutes'
);

const { StockAnalyticsInventoryRoutes } = lazyImport(
  () => import('@/features/stock_analytics_inventory'),
  'StockAnalyticsInventoryRoutes'
);

const { StockAnalyticsByMonthRoutes } = lazyImport(
  () => import('@/features/stock_analytics_by_month'),
  'StockAnalyticsByMonthRoutes'
);

const { InventoryRoutes } = lazyImport(() => import('@/features/inventory'), 'InventoryRoutes');

const { SalesChangingListRoutes } = lazyImport(
  () => import('@/features/sales_changing_list'),
  'SalesChangingListRoutes'
);

const { SalesDailyRoutes } = lazyImport(() => import('@/features/sales_daily'), 'SalesDailyRoutes');

const { SalesAnalyticsRoutes } = lazyImport(
  () => import('@/features/sales_analytics'),
  'SalesAnalyticsRoutes'
);
const { CollectionPlanRoutes } = lazyImport(
  () => import('@/features/collection_plan_detail'),
  'CollectionPlanRoutes'
);
const { CollectionStatusRoutes } = lazyImport(
  () => import('@/features/collection_status_list'),
  'CollectionStatusRoutes'
);
const { TradeHistoryRoutes } = lazyImport(
  () => import('@/features/trade_history'),
  'TradeHistoryRoutes'
);
const { PartialShipmentRoutes } = lazyImport(
  () => import('@/features/partial_shipment'),
  'PartialShipmentRoutes'
);

const { PurchaseUnitPriceUpdateRoutes } = lazyImport(
  () => import('@/features/purchase_unit_price_update'),
  'PurchaseUnitPriceUpdateRoutes'
);

const { InternalTransactionRoutes } = lazyImport(
  () => import('@/features/internal_transaction'),
  'InternalTransactionRoutes'
);

const { WarehouseMoveDetailRoutes } = lazyImport(
  () => import('@/features/warehousemove_detail'),
  'WarehouseMoveDetailRoutes'
);

const { ShipmentDetailRoutes } = lazyImport(
  () => import('@/features/shipment_detail'),
  'ShipmentDetailRoutes'
);

const App = () => {
  return (
    <Layout>
      <Suspense
        fallback={
          <div className="h-full w-full flex items-center justify-center">
            <Spinner size="xl" />
          </div>
        }
      >
        <AccessControl>
          <Outlet />
        </AccessControl>
      </Suspense>
    </Layout>
  );
};

export const protectedRoutes = [
  {
    path: '/app',
    element: <App />,
    children: [
      { path: '/receive_order/*', element: <ReceiveOrderRoutes /> },
      { path: '/shipment_detail/*', element: <ShipmentDetailRoutes /> },
      { path: '/purchase_analytics/*', element: <PurchaseAnalyticsRoutes /> },
      { path: '/purchase_daily/*', element: <PurchaseDailyRoutes /> },
      { path: '/purchase_detail/*', element: <PurchaseDetailRoutes /> },
      { path: '/purchase_order_detail/*', element: <PurchaseOrderDetailRoutes /> },
      { path: '/production_detail/*', element: <ProductionDetailRoutes /> },
      { path: '/production/*', element: <ProductionRoutes /> },
      { path: '/product_group/*', element: <ProductGroupRoutes /> },
      { path: '/product/*', element: <ProductRoutes /> },
      { path: '/customer/*', element: <CustomerRoutes /> },
      { path: '/vendor/*', element: <VendorRoutes /> },
      { path: '/deliver/*', element: <DeliverRoutes /> },
      { path: '/division/*', element: <DivisionRoutes /> },
      { path: '/warehouse_move/*', element: <WarehouseMoveRoutes /> },
      { path: '/warehouse/*', element: <WarehouseRoutes /> },
      { path: '/estimate/*', element: <EstimateRoutes /> },
      { path: '/ledger_customer/*', element: <CustomerLedgerRoutes /> },
      { path: '/ledger_vendor/*', element: <VendorLedgerRoutes /> },
      { path: '/component/*', element: <ComponentRoutes /> },
      { path: '/demand/*', element: <DemandRoutes /> },
      { path: '/payment/*', element: <PaymentRoutes /> },
      { path: '/customer_budget', element: <CustomerBudgetRoutes /> },
      { path: '/user/*', element: <UserRoutes /> },
      { path: '/deposit/*', element: <DepositRoutes /> },
      { path: '/deposit_daily', element: <DepositDailyRoutes /> },
      { path: '/shipment/*', element: <ShipmentRoutes /> },
      { path: '/send_order/*', element: <SendOrderRoutes /> },
      { path: '/arrival/*', element: <ArrivalRoutes /> },
      { path: '/withdrawal_detail', element: <WithdrawalDetailRoutes /> },
      { path: '/withdrawal/*', element: <WithdrawalRoutes /> },
      { path: '/sales_unit_price_update', element: <SalesUnitPriceUpdateRoutes /> },
      { path: '/send_order_lot', element: <SendOrderLotRoutes /> },
      { path: '/outsourcer_sales/*', element: <OutsourcerSalesRoutes /> },
      { path: '/trust_inventory/*', element: <TrustInventoryRoutes /> },
      { path: '/directly_purchase_list/*', element: <DirectlyPurchaseListRoutes /> },
      { path: '/customer_price', element: <CustomerPriceRoutes /> },
      { path: '/sales_trust', element: <SalesTrustRoutes /> },
      { path: '/receivable_balance_list', element: <ReceivableBalanceRoutes /> },
      { path: '/payable_balance_list', element: <PayableBalanceRoutes /> },
      { path: '/deposit_detail', element: <DepositDetailRoutes /> },
      { path: '/inventory/*', element: <InventoryRoutes /> },
      { path: '/received_order_detail/*', element: <ReceivedOrderDetailRoutes /> },
      { path: '/sales_detail/*', element: <SalesDetailRoutes /> },
      { path: '/sales_changing_list/*', element: <SalesChangingListRoutes /> },
      { path: '/sales_daily/*', element: <SalesDailyRoutes /> },
      { path: '/cloth_progress', element: <ClothProgressRoutes /> },
      { path: '/stock_adjust/*', element: <StockAdjustRoutes /> },
      { path: '/stock_list/*', element: <StockListRoutes /> },
      { path: '/stock_analytics', element: <StockAnalyticsRoutes /> },
      { path: '/stock_analytics_inventory/*', element: <StockAnalyticsInventoryRoutes /> },
      { path: '/stock_analytics_by_month/*', element: <StockAnalyticsByMonthRoutes /> },
      { path: '/sales_analytics/*', element: <SalesAnalyticsRoutes /> },
      { path: '/collection_plan_detail/*', element: <CollectionPlanRoutes /> },
      { path: '/collection_status_list/*', element: <CollectionStatusRoutes /> },
      { path: '/arrived_delivery_history/*', element: <TradeHistoryRoutes /> },
      { path: '/partial_shipment/*', element: <PartialShipmentRoutes /> },
      { path: '/purchase_unit_price_update', element: <PurchaseUnitPriceUpdateRoutes /> },
      { path: '/internal_transaction', element: <InternalTransactionRoutes /> },
      { path: '/warehousemove_detail', element: <WarehouseMoveDetailRoutes /> },
      { path: '/', element: <Dashboard /> },
      { path: '*', element: <Navigate to="." /> },
    ],
  },
  {
    path: '/',
    element: <Navigate to="/app" />,
  },
];
